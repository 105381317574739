import { Box, Divider, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AuthContext } from "components/Providers/AuthProvider";
import { useContext, useMemo, useState } from "react";
import { Inventory } from "types/Inventory";
import { PaypalButton } from "components/DashboardComponents/Payments/PaypalButton";
import { inventoryCategories, priceWithVAT } from "utilities/costants/genericCostants";
import { ReactComponent as BoltSign } from "assets/images/Bolt_sign.svg";
import { MarketCountryFiscalCode } from "../MarketCountryFiscalCode";
import { useTranslation } from "react-i18next";

type PurchaseRecapProps = {
	gemsPack: Inventory.GemsPack;
	selected: Inventory.GemsPacksName;
	onClose: () => void;
};

const FISCAL_CODE_REGEX = /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/;

export const PurchaseRecap = ({ gemsPack, selected, onClose }: PurchaseRecapProps) => {
	const { t } = useTranslation("dashboard");
	const { profile } = useContext(AuthContext);
	const [fiscalCode, setFiscalCode] = useState<string>("");
	const [countrySelected, setCountrySelected] = useState<string>("");
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const isFiscalCodeValid = useMemo(() => {
		return FISCAL_CODE_REGEX.test(fiscalCode);
	}, [fiscalCode]);

	const showPaypalButton = useMemo(() => {
		if (profile.fiscalCode !== undefined) return true;
		if (countrySelected === "") return false;
		if (countrySelected !== "Italy") return true;
		if (countrySelected === "Italy" && isFiscalCodeValid) return true;
		return false;
	}, [profile.fiscalCode, countrySelected, isFiscalCodeValid]);

	const handleFiscalCode = (value: string) => {
		setFiscalCode(value);
	};

	const handleCountry = (value: string) => {
		setCountrySelected(value);
	};

	const handleOnClose = () => {
		setFiscalCode("");
		setCountrySelected("");
		onClose();
	};

	return (
		<>
			<Stack
				maxWidth={600}
				minHeight={700}
				justifyContent="space-between"
				direction="column"
				sx={{
					width: "100%",
					backgroundColor: "#1A1A1A",
					border: "1px solid #FFFFFF5A",
					borderRadius: 4,
					pt: 3,
					pb: 4,
					"& *": {
						fontFamily: "Sora",
					},
				}}>
				<Box>
					<Typography variant="h6" textTransform="uppercase" fontFamily="Sora" fontWeight={700} px={{ xs: 2, md: 7 }}>
						{t("market-page.details")}
					</Typography>
					<Typography variant="h4" textAlign="center" textTransform="capitalize" fontWeight={500} mt={1.5}>
						{gemsPack[selected].name}
					</Typography>
					<Divider
						orientation="horizontal"
						sx={{
							borderColor: "#FFFFFF5A",
							opacity: 1,
							my: 2,
						}}
					/>
					<Stack direction="column" px={{ xs: 3, md: 7 }}>
						<Stack direction="row" justifyContent="space-between">
							<Typography variant="h6" fontFamily="Sora" textTransform="capitalize">
								{t("market-page.price")}
							</Typography>
							<Typography variant="h5" fontFamily="Sora">
								€ {priceWithVAT(gemsPack[selected].price)}
							</Typography>
						</Stack>

						<Divider
							orientation="horizontal"
							sx={{
								borderColor: "#FFFFFF5A",
								opacity: 1,
								my: 2,
							}}
						/>

						<Stack direction="row" justifyContent="space-between">
							<Typography variant="h6" fontFamily="Sora" textTransform="capitalize">
								{t("market-page.balance.current")}
							</Typography>
							<Stack direction="row" gap={1} alignItems="center">
								<Typography variant="h6" fontFamily="Sora">
									{profile.bolts.toFixed(isMobile ? 2 : 6)}
								</Typography>
								<Box component={BoltSign} fill="white" width="1.5em" />
							</Stack>
						</Stack>

						<Divider
							orientation="horizontal"
							sx={{
								borderColor: "#FFFFFF5A",
								opacity: 1,
								my: 2,
							}}
						/>

						<Stack direction="row" justifyContent="space-between">
							<Typography variant="h6" fontFamily="Sora" textTransform="capitalize">
								{t("market-page.balance.new")}
							</Typography>
							<Stack direction="row" gap={1} alignItems="center">
								<Typography variant="h6" fontFamily="Sora">
									{(profile.bolts + gemsPack[selected].boltsGiven).toFixed(isMobile ? 2 : 6)}
								</Typography>
								<Box component={BoltSign} fill="white" width="1.5em" />
							</Stack>
						</Stack>

						<Divider
							orientation="horizontal"
							sx={{
								borderColor: "#FFFFFF5A",
								opacity: 1,
								my: 2,
							}}
						/>

						<Stack direction="row" justifyContent="space-between">
							<Typography variant="h6" fontFamily="Sora" textTransform="capitalize">
								{t("market-page.earnings")}
							</Typography>
							<Stack direction="row" gap={1} alignItems="center">
								<Typography variant="h5" fontFamily="Sora" fontWeight={600} color="var(--success)">
									+{gemsPack[selected].boltsGiven}
								</Typography>
								<Box component={BoltSign} fill="var(--success)" width="1.5em" />
							</Stack>
						</Stack>
					</Stack>

					<Divider
						orientation="horizontal"
						sx={{
							borderColor: "#FFFFFF5A",
							opacity: 1,
							my: 2,
						}}
					/>

					{profile.fiscalCode === undefined && (
						<MarketCountryFiscalCode
							countrySelected={countrySelected}
							fiscalCode={fiscalCode}
							handleCountry={handleCountry}
							handleFiscalCode={handleFiscalCode}
						/>
					)}
				</Box>
				<Box
					sx={{
						width: "80%",
						maxWidth: "80%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						flexGrow: 1,
						mx: "auto",
						mt: 5,
					}}>
					{profile.fiscalCode === undefined && countrySelected === "" && (
						<Typography variant="h6" fontFamily="Sora" textAlign="center">
							{t("market-page.invalid.country")}
						</Typography>
					)}
					{profile.fiscalCode === undefined && countrySelected === "Italy" && !isFiscalCodeValid && (
						<Typography variant="h6" fontFamily="Sora" textAlign="center">
							{t("market-page.invalid.fiscal-code")}
						</Typography>
					)}
					{showPaypalButton && (
						<PaypalButton
							cart={{
								boltsGiven: gemsPack[selected].boltsGiven,
								uid: gemsPack[selected].uid,
								name: gemsPack[selected].name,
								category: inventoryCategories.GEMS_PACK,
							}}
							onSuccess={handleOnClose}
							boltsUsedForDiscount={0}
							fiscalCode={profile.fiscalCode || fiscalCode}
						/>
					)}
				</Box>
			</Stack>
		</>
	);
};
