import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Button, Typography, Table, TableBody, TableRow, TableCell, TableHead } from "@mui/material";
import { doc, query, collection, CollectionReference, orderBy, getDocs, updateDoc } from "firebase/firestore";
import { useCallback, useContext, useEffect, useState } from "react";
import { MARKET_TABS } from "..";
import { Payments } from "types/Payments";
import { FirebaseContext } from "../../../../Providers/FirebaseProvider";

export const ReceiptsRewardsPanel = ({ value }: { value: number }): JSX.Element => {
	const { myFS } = useContext(FirebaseContext);
	// const { profile } = useContext(AuthContext);
	const [rewardsReceipts, setRewardsReceipts] = useState([] as (Payments.PayPal.Receipts & { id: string; index: number })[]);

	const getRewardsReceipts = useCallback(async (): Promise<void> => {
		try {
			const docRef = query<Payments.PayPal.Receipts>(
				collection(myFS, "payments/paypal/receipts") as CollectionReference<Payments.PayPal.Receipts>,
				orderBy("financialReceipt.date", "desc")
			);
			const querySnap = await getDocs<Payments.PayPal.Receipts>(docRef);
			let rewardsReceipts = [] as (Payments.PayPal.Receipts & { id: string; index: number })[];
			let index = 0;

			querySnap.forEach((doc) => {
				rewardsReceipts.push({ ...doc.data(), id: doc.id, index: index } as Payments.PayPal.Receipts & { id: string; index: number });
				index = index + 1;
			});

			setRewardsReceipts(rewardsReceipts);
		} catch (error) {
			console.log(error);
		}
	}, [myFS]);

	useEffect(() => {
		if (value === MARKET_TABS.RECEIPTS) getRewardsReceipts();
	}, [value, getRewardsReceipts]);

	async function markAsCompleted(receiptId: string): Promise<void> {
		var answer = window.confirm(`Are you sure you want to mark this receipt (${receiptId}) as COMPLETED?`);
		if (answer) {
			await updateDoc(doc(myFS, `payments/paypal/receipts/${receiptId}`), {
				completed: true,
			});

			// update the local state
			const rewardsReceiptsTMP = [...rewardsReceipts];
			const receipt = rewardsReceipts.find(({ id }) => id === receiptId);
			const index = rewardsReceiptsTMP.indexOf(receipt);
			rewardsReceiptsTMP[index].completed = true;
			setRewardsReceipts(rewardsReceiptsTMP);
		}
	}

	return (
		<Grid
			container
			hidden={value !== MARKET_TABS.RECEIPTS}
			display="flex"
			justifyContent="center"
			alignItems="center"
			gap={1}
			mx={20}
			maxHeight="80vh"
			overflow="scroll">
			{rewardsReceipts != null && rewardsReceipts !== undefined && rewardsReceipts.length !== 0 && (
				<Table
					stickyHeader
					sx={{
						".MuiTableCell-root": {
							color: "white",
							p: 1,
						},
						".MuiTableHead-root .MuiTableCell-root": {
							color: "black",
							textAlign: "center",
						},
					}}>
					<TableHead>
						<TableRow>
							<TableCell sx={{ borderRight: "3px solid red" }}>
								<Typography variant="h5">Firebase ID</Typography>
							</TableCell>
							<TableCell sx={{ borderRight: "3px solid red" }}>
								<Typography variant="h5">Creation Date</Typography>
							</TableCell>
							<TableCell sx={{ borderRight: "3px solid red" }}>
								<Typography variant="h5">Payment Status</Typography>
							</TableCell>
							<TableCell sx={{ borderRight: "3px solid red" }}>
								<Typography variant="h5">User</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="h5">Financial Receipt</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rewardsReceipts.map((rewardReceipt) => {
							const creationDate = rewardReceipt.financialReceipt.date.toDate();
							const creationDateString = creationDate.getUTCDate() + "/" + (creationDate.getUTCMonth() + 1) + "/" + creationDate.getUTCFullYear();
							const creationTimeString = creationDate.getUTCHours() + ":" + creationDate.getUTCMinutes() + ":" + creationDate.getUTCSeconds();
							const creationDateFullString = creationDateString + " " + creationTimeString + " UTC";

							return (
								<TableRow
									sx={{
										".MuiTableCell-root .MuiTableCell-root": {
											border: "none",
										},
									}}>
									<TableCell>{rewardReceipt.id}</TableCell>
									<TableCell>{creationDateFullString}</TableCell>
									<TableCell>
										<Typography
											variant="body1"
											sx={{
												bgcolor:
													rewardReceipt.status === Payments.PayPal.Status.PENDING
														? "warning.main"
														: rewardReceipt.status === Payments.PayPal.Status.COMPLETED
														? "success.main"
														: "error.main",
												px: 5,
											}}>
											{rewardReceipt.status.toUpperCase()}
										</Typography>
									</TableCell>
									<TableCell>
										<Table
											sx={{
												".MuiTableCell-root": {
													color: "white",
													p: 1,
													textAlign: "center",
												},
												".MuiTableHead-root .MuiTableCell-root": {
													bgcolor: "white",
													color: "black",
													textAlign: "center",
												},
											}}>
											<TableHead>
												<TableRow>
													<TableCell>
														<Typography variant="h6">Username</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h6">Email</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h6">UID</Typography>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<TableRow>
													<TableCell>{rewardReceipt.user.username}</TableCell>
													<TableCell>{rewardReceipt.user.email}</TableCell>
													<TableCell>{rewardReceipt.user.uid}</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableCell>
									<TableCell>
										<Table
											sx={{
												".MuiTableCell-root": {
													color: "white",
													p: 1,
													textAlign: "center",
												},
												".MuiTableHead-root .MuiTableCell-root": {
													bgcolor: "white",
													color: "black",
													textAlign: "center",
												},
											}}>
											<TableHead>
												<TableRow>
													<TableCell>
														<Typography variant="h6">Item Requested</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h6">Item Price</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h6">VAT</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h6">Total</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h6">Bolts Used</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h6">Discount %</Typography>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<TableRow>
													<TableCell>{rewardReceipt.financialReceipt.itemRequested}</TableCell>
													<TableCell>€ {(rewardReceipt.financialReceipt.itemPrice / 100).toFixed(2)}</TableCell>
													<TableCell>€ {(rewardReceipt.financialReceipt.VAT / 100).toFixed(2)}</TableCell>
													<TableCell>€ {(rewardReceipt.financialReceipt.total / 100).toFixed(2)}</TableCell>
													<TableCell>{rewardReceipt.financialReceipt.boltsUsed}</TableCell>
													<TableCell>{rewardReceipt.financialReceipt.discount}</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableCell>
									{rewardReceipt.category === "rewards" && rewardReceipt.status !== Payments.PayPal.Status.PENDING && (
										<TableCell>
											{rewardReceipt.completed ? (
												<Typography
													variant="body1"
													sx={{
														display: "flex",
														justifyContent: "center",
														color: "success.main",
													}}>
													<FontAwesomeIcon icon={faCheckCircle as IconProp} size="3x" />
												</Typography>
											) : (
												<Button
													type="button"
													sx={{
														display: "flex",
														alignItems: "center",
														gap: 1,
														backgroundColor: "warning.main",
														color: "white",
														border: 0,
														padding: "10px 20px",
														margin: "0 auto",
														borderRadius: 2,
														boxShadow: 10,
														cursor: "pointer",
														fontSize: 16,
														fontWeight: "bold",
														lineHeight: 1.5,
														"&:hover": {
															backgroundColor: "warning.dark",
														},
													}}
													onClick={() => markAsCompleted(rewardReceipt.id)}>
													<FontAwesomeIcon icon={faCheck as IconProp} className="fa-xl" /> MARK AS COMPLETED
												</Button>
											)}
										</TableCell>
									)}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			)}
		</Grid>
	);
};
