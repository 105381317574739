import { Divider, Stack, Typography } from "@mui/material";
import { InputText } from "components/Input/InputText";
import { CustomSelect } from "components/Select/CustomSelect";
import { countries, ICountry } from "countries-list";
import { useTranslation } from "react-i18next";

type MarketCountryFiscalCodetsxProps = {
	countrySelected: string;
	fiscalCode: string;
	handleCountry: (value: string) => void;
	handleFiscalCode: (value: string) => void;
};

const countryCodes = Object.keys(countries);
const countryNames = countryCodes.map((code) => (countries[code] as ICountry).name);

export const MarketCountryFiscalCode = ({ countrySelected, fiscalCode, handleCountry, handleFiscalCode }: MarketCountryFiscalCodetsxProps) => {
	const { t } = useTranslation("dashboard");

	return (
		<Stack direction="column" px={{ xs: 3, md: 7 }}>
			<Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between" alignItems={{ xs: "flex-start", md: "center" }}>
				<Typography variant="h6" fontFamily="Sora" textTransform="capitalize">
					{t("market-page.country")} <sup>*</sup>
				</Typography>
				<CustomSelect
					key={`country-select`}
					placeholder={t("market-page.select-country")}
					sx={{
						width: 250,
						height: 35,
						mx: { xs: "auto", md: 0 },
					}}
					onChange={handleCountry}
					values={countryNames}
				/>
			</Stack>
			{countrySelected === "Italy" && (
				<>
					<Divider
						orientation="horizontal"
						sx={{
							borderColor: "#FFFFFF5A",
							opacity: 1,
							my: 2,
						}}
					/>
					<Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between" alignItems={{ xs: "flex-start", md: "center" }}>
						<Typography variant="h6" fontFamily="Sora" textTransform="capitalize">
							{t("market-page.fiscal-code")} <sup>*</sup>
						</Typography>
						<InputText
							initialValue={fiscalCode}
							type="text"
							id="txtFC"
							idErr="errFC"
							placeholder=""
							onChange={(e) => handleFiscalCode(e.target.value.toUpperCase())}
							sx={{
								width: 240,
								mx: { xs: "auto", md: 0 },
							}}
						/>
					</Stack>
				</>
			)}
		</Stack>
	);
};
