export const MARKET_TABS = {
	ADD: 0,
	EDIT: 1,
	RECEIPTS: 2,
} as const;

export const MARKET_ITEMS_TABS = {
	GEMS_PACKS: 0,
	REWARDS: 1,
	SUBSCRIPTION: 2,
} as const;

export * from "./EditSubscription";
