export const itemInitialValue = {
	name: "",
	price: 0,
	priceInBolts: 0,
	image: "",
	imageName: "",
	uid: 0,
};

export const initialValues = {
	service: "",
	reward: "",
	description: {
		en: "",
		it: "",
	},
	image: "",
	imageName: "",
	items: [itemInitialValue],
};

export * from "./AddRewardsPanel";
export * from "./EditDialog";
export * from "./EditRewardsPanel";
export * from "./ReceiptsRewardsPanel";
