import Slider from "@mui/material/Slider/Slider";
import { Mark } from "@mui/material/Slider/useSlider.types";
import { makeStyles } from "@mui/styles";

const styles = makeStyles({
	root: {
		color: "white",
		height: 8,
		"& *": {
			fontFamily: "Sora, sans-serif !important",
		},
		"& .MuiSlider-thumb": {
			backgroundColor: "red",
			width: "15px",
			height: "15px",
			"&:hover": {
				boxShadow: "0px 0px 0px 6px rgba(255, 255, 255, 0.20)",
			},
		},
		"& .MuiSlider-track": {
			border: "none",
		},
		"& .MuiSlider-valueLabel": {
			background: "linear-gradient(90deg, #AB00C74D 0%, #0075FF4D 100%)",
			"&:before": {
				background: "linear-gradient(90deg, #AB00C74D 0%, #0075FF4D 100%)",
				clipPath: "polygon(0% 100%,100% 0%,1000% 0%)",
			},
		},
		"& .MuiSlider-markLabel": {
			color: "white",
			marginTop: "5px",
			"&:before": {
				content: "''",
				width: "6px",
				height: "6px",
				position: "absolute",
				backgroundColor: "white",
				borderRadius: "50%",
				left: "50%",
				top: "-40%",
				transform: "translateX(-50%)",
			},
			"&:after": {
				content: "''",
				width: "1px",
				height: "12px",
				position: "absolute",
				backgroundColor: "white",
				left: "50%",
				top: "-90%",
				transform: "translateX(-50%)",
			},
		},
	},
});

export const CustomSlider = ({
	disabled,
	ariaLabel,
	defaultValue,
	onChange,
	step = null,
	min = 0,
	max = 100,
	marks = null,
}: {
	disabled: boolean;
	ariaLabel: string;
	defaultValue: number;
	step?: number;
	min?: number;
	max?: number;
	marks?: Mark[];
	onChange: (event: Event, value: number | number[]) => void;
}) => {
	const classes = styles();

	function valueText(value: number) {
		return `${value}%`;
	}

	return (
		<Slider
			min={min}
			max={max}
			step={step}
			getAriaValueText={valueText}
			valueLabelFormat={valueText}
			valueLabelDisplay="auto"
			disabled={disabled}
			onChange={(event: any, value: any) => {
				onChange(event, value);
			}}
			marks={marks}
			className={classes.root}
			defaultValue={defaultValue}
			aria-label={ariaLabel}
		/>
	);
};
