import { convertFromHTML, convertToHTML } from "draft-convert";
import { useField } from "formik";
import { useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

type CustomTextAreaProps = {
	name: string;
	placeholder: string;
	initialValue?: string;
	wrapperStyle?: React.CSSProperties;
};

export const CustomTextArea = ({ name, placeholder, initialValue, wrapperStyle }: CustomTextAreaProps): JSX.Element => {
	const [field] = useField(name);
	const [editorState, setEditorState] = useState(() => EditorState.createWithContent(convertFromHTML(initialValue || "<p></p>")));

	useEffect(() => {
		if (field.value === "<p></p>" || field.value === "") {
			setEditorState(EditorState.createEmpty());
		}
	}, [field.value]);

	return (
		<Editor
			editorState={editorState}
			onEditorStateChange={setEditorState}
			toolbar={{
				options: ["inline", "blockType", "list", "textAlign", "link", "emoji", "image", "remove", "history"],
			}}
			placeholder={placeholder}
			wrapperStyle={{
				backgroundColor: "#fff",
				color: "#000",
				...wrapperStyle,
			}}
			editorStyle={{
				paddingInline: "1rem",
				maxHeight: "300px",
				minHeight: "300px",
			}}
			onChange={(content) => {
				try {
					field.onChange({ target: { name: name, value: convertToHTML(editorState.getCurrentContent()) } });
				} catch (e) {
					console.log(e);
				}
			}}
			handlePastedText={() => false}
		/>
	);
};
