import { Dialog, DialogContent, DialogTitle, Grid, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { Inventory } from "types/Inventory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { GemPackChoice, PurchaseRecap } from ".";

type MarketGemDialogProps = {
	gemsPack: Inventory.GemsPack;
	openGemsDialog: boolean;
	setOpenGemsDialog: Dispatch<SetStateAction<boolean>>;
};

export const MarketGemDialog = ({ gemsPack, openGemsDialog, setOpenGemsDialog }: MarketGemDialogProps) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const [selected, setSelected] = useState<Inventory.GemsPacksName>("silverBox");

	const handleClose = () => {
		setSelected("silverBox");
		setOpenGemsDialog(false);
	};

	return (
		<Dialog
			open={openGemsDialog}
			onClose={handleClose}
			fullScreen={fullScreen}
			maxWidth="xl"
			sx={{
				"& .MuiDialog-paper": {
					width: "100%",
				},
				"& *": {
					fontFamily: "Sora",
				},
			}}>
			<DialogTitle
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					alignItems: "center",
				}}>
				<IconButton
					onClick={handleClose}
					sx={{
						color: "rgba(255, 255, 255, 0.6)",
						transition: "all 0.3s ease-in-out",
						":hover": {
							rotate: "90deg",
							scale: 1.2,
							color: "var(--error)",
						},
					}}>
					<FontAwesomeIcon icon={faTimes as IconProp} className="fa-xl" />
				</IconButton>
			</DialogTitle>
			<DialogContent
				sx={{
					width: "100%",
					pb: 5,
				}}>
				<Grid container alignItems="center" justifyContent="space-around">
					<GemPackChoice gemsPack={gemsPack} selected={selected} setSelected={setSelected} />

					<PurchaseRecap gemsPack={gemsPack} selected={selected} onClose={handleClose} />
				</Grid>
			</DialogContent>
		</Dialog>
	);
};
