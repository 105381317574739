import { FunctionComponent, ReactNode, SVGProps, useContext, useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { useSnackbar } from "components/Providers/SnackbarProvider";
import { FirebaseContext } from "components/Providers/FirebaseProvider";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { doc, updateDoc } from "firebase/firestore";
import { Inventory } from "types/Inventory";

export const EditDialog = ({
	open,
	onClose,
	gemPack,
	gemPackKey,
	Image,
	refetchNewItems,
}: {
	open: boolean;
	onClose: () => void;
	gemPack: Inventory.Item;
	gemPackKey: Inventory.GemsPacksName;
	Image: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>;
	refetchNewItems: () => Promise<void>;
}): JSX.Element => {
	const { myFS } = useContext(FirebaseContext);
	const { openSnackbar } = useSnackbar();

	async function editGemPack(gemPack: Inventory.Item): Promise<void> {
		try {
			const docRef = doc(myFS, "inventory/items");

			await updateDoc(docRef, {
				[`gemsPack.${gemPackKey}`]: gemPack,
			});

			openSnackbar("Success", "Gem Pack edited successfully", "success");
			refetchNewItems();
			onClose();
		} catch (error) {
			console.log(error);
			if (error) {
				openSnackbar("Error", error.message, "error");
			}
		}
	}

	async function handleSubmit(values: Inventory.Item, formikHelpers: FormikHelpers<Inventory.Item>): Promise<void> {
		console.log(values);

		// check if every field is not empty
		if (values.name === "" || values.price === 0 || values.uid < 0 || values.boltsGiven === 0) {
			openSnackbar("Error", "Please fill all the fields", "error");
			return;
		}

		const gemPack: Inventory.Item = {
			name: values.name,
			price: Number(values.price),
			uid: values.uid,
			boltsGiven: Number(values.boltsGiven),
		};
		await editGemPack(gemPack);
	}

	return (
		<Box
			component={Dialog}
			sx={{
				"& .MuiPaper-root": {
					minHeight: "400px",
					minWidth: "60vw",
				},
			}}
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle>Edit Gems Pack</DialogTitle>
			<DialogContent>
				<Formik enableReinitialize initialValues={gemPack} onSubmit={handleSubmit}>
					{({ values, setFieldValue }) => (
						<Form style={{ width: "100%" }}>
							<Grid container gap={1} flexDirection="column" alignItems="center">
								<Grid item display="flex" flexDirection="row" gap={1}>
									<Box component="label" htmlFor="name" display="flex" justifyContent="center" alignItems="center">
										<span>Name</span>
									</Box>
									<Box component={Field} name="name" placeholder="Name" type="text" required />
								</Grid>
								<Grid item display="flex" flexDirection="row" gap={1}>
									<Box component="label" htmlFor="boltsGiven" display="flex" justifyContent="center" alignItems="center">
										<span>Bolts Given</span>
									</Box>
									<Box component={Field} name="boltsGiven" placeholder="Bolts Given" type="text" required />
								</Grid>
								<Grid item display="flex" flexDirection="row" gap={1}>
									<Box component="label" htmlFor="price" display="flex" justifyContent="center" alignItems="center">
										<span>Price</span>
									</Box>
									<Box component={Field} name="price" placeholder="Price" type="text" required />
									<Box component="label" htmlFor="price" display="flex" justifyContent="center" alignItems="center">
										<span>{(values.price / 100).toFixed(2)} €</span>
									</Box>
								</Grid>
								<Box display="flex" textAlign="center" justifyContent="center" alignItems="center" gap={5}>
									<Image />
								</Box>

								<Button variant="contained" color="success" type="submit" sx={{ width: "20%" }}>
									<Box display="flex" textAlign="center" justifyContent="center" alignItems="center" gap={1}>
										<FontAwesomeIcon icon={faSave as IconProp} className="fa-xl" />
										SAVE
									</Box>
								</Button>
							</Grid>
						</Form>
					)}
				</Formik>
			</DialogContent>
		</Box>
	);
};
