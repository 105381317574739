import { Box } from "@mui/material";

type MarketBoxImageProps = {
	src: string;
};

export const MarketBoxImage = ({ src }: MarketBoxImageProps) => (
	<Box
		component="div"
		sx={{
			width: "100%",
			height: "30em",
			backgroundPosition: "center",
			backgroundSize: "contain",
			backgroundRepeat: "no-repeat",
			backgroundImage: `url(${src})`,
		}}>
		<Box
			component="div"
			sx={{
				width: "100%",
				height: "30em",
				backgroundImage: `url(${src})`,
				backgroundSize: "contain",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				backdropFilter: "blur(30px) saturate(3)",
			}}
		/>
	</Box>
);
