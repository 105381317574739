import { Box, SxProps, Theme } from "@mui/material";
import { useState } from "react";
import DropdownList from "react-widgets/esm/DropdownList";

export const CustomSelect = ({
	onChange,
	placeholder,
	values,
	sx,
}: {
	onChange: (value: string) => void;
	placeholder?: string;
	values: string[];
	sx?: SxProps<Theme>;
}) => {
	const [value, setValue] = useState<string>("");

	return (
		<>
			<Box
				component={DropdownList}
				sx={{
					"& .rw-widget-container": {
						height: "100%",
						borderRadius: "100px",
						background: "#111",
						boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
					},
					"& .rw-btn.rw-picker-caret": {
						color: "white",
					},
					"& .rw-popup": {
						background: "#111",
					},
					"& .rw-list-option": {
						color: "white",
						"&:hover": {
							background: "#333",
						},
					},
					"& .rw-dropdownlist-search": {
						color: "white",
						fontSize: "1rem",
					},
					"& .rw-dropdown-list-value": {
						color: "white",
						fontSize: "1rem",
					},
					...sx,
				}}
				value={value}
				placeholder={placeholder}
				onChange={(newValue) => {
					setValue(newValue as string);
					onChange(newValue as string);
				}}
				data={values}
			/>
		</>
	);
};
