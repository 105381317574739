import { Box, Card, CardContent, keyframes, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MarketBoxImage } from "./MarketBoxImage";

const pulseAnimation = (color: string) => keyframes`
	0% {
        box-shadow: 0px 0px 20px -10px ${color};
    }

    100% {
        box-shadow: 0px 0px 20px 5px ${color};
    }
`;

type MarketMainCardProps = {
	imageSrc: string;
	handleBuy: () => void;
	children: JSX.Element;
};

export const MarketMainCard = ({ imageSrc, handleBuy, children }: MarketMainCardProps): JSX.Element => {
	const { t } = useTranslation("dashboard");
	return (
		<Box
			component={Card}
			onClick={handleBuy}
			sx={{
				width: "100%",
				height: "100%",
				display: "flex",
				flexDirection: "column",
				position: "relative",
				transition: "all 0.3s ease-in-out",
				":hover": {
					transform: "scale(1.05)",
				},
			}}
			className="market-card">
			<Typography
				variant="h4"
				sx={{
					width: "50%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					borderRadius: "30px",
					backgroundImage: "linear-gradient(90deg, #fff 0%, #fff 100%)",
					color: "rgba(0,0,0,1)",
					transition: "all 0.3s ease-in-out",
					zIndex: 1,
					opacity: 0,
					textTransform: "uppercase",
					fontFamily: "Sora",
					":hover": {
						background: "linear-gradient(90deg, #AB00C7 0.58%, #0075FF 97.84%) !important",
					},
					".market-card:hover &": {
						animation: `${pulseAnimation("#0176ff")} 0.7s infinite alternate`,
						opacity: 1,
					},
				}}
				className={`btn-fill`}>
				{t("buy")}
			</Typography>
			<CardContent
				sx={{
					transition: "all 0.3s ease-in-out",
					py: 7,
					".market-card:hover &": {
						cursor: "pointer",
						filter: "blur(4px)",
					},
				}}>
				<MarketBoxImage src={imageSrc} />
				<Box
					sx={{
						boxShadow: "0px 0px 100px 150px rgba(0,0,0,1)",
						position: "relative",
						zIndex: 2,
					}}>
					{children}
				</Box>
			</CardContent>
		</Box>
	);
};
