import { useCallback, useContext, useEffect, useState } from "react";
import { collection, doc, orderBy, query, getDocs, deleteDoc, CollectionReference } from "firebase/firestore";
import { FirebaseContext } from "../../Providers/FirebaseProvider";
import { Grid } from "@mui/material";
import { GiftCardRequest, GiftCardRequestData } from "../../../types/GiftCard";

export const DashboardAdminGiftCard = (): JSX.Element => {
	const { myFS } = useContext(FirebaseContext);

	const [show, setShow] = useState(false);
	const [giftCardsRequests, setGiftCardsRequests] = useState([] as GiftCardRequest[]);

	const getGiftCardRequests = useCallback(async (): Promise<void> => {
		try {
			const docRef = query<GiftCardRequestData>(
				collection(myFS, "giftCardRequests") as CollectionReference<GiftCardRequestData>,
				orderBy("creationDate", "desc")
			);
			const querySnap = await getDocs<GiftCardRequestData>(docRef);
			let giftCardsRequests = [] as GiftCardRequest[];
			let index = 0;

			querySnap.forEach((doc) => {
				if (doc.data().giftCard) giftCardsRequests.push({ ...doc.data(), id: doc.id, index: index } as GiftCardRequest);
				index = index + 1;
			});
			setGiftCardsRequests(giftCardsRequests);
		} catch (error) {
			console.log(error);
		}
	}, [myFS]);

	useEffect(() => {
		if (show) getGiftCardRequests();
	}, [show, getGiftCardRequests]);

	async function deleteGiftCardRequest(requestId: string): Promise<void> {
		var answer = window.confirm("Are you sure to delete the request giftcard with id : " + requestId);
		if (answer) {
			//Delete request on the DB
			await deleteDoc(doc(myFS, `giftCardRequests/${requestId}`));
			//Delete the request locally
			const giftCardsRequestsTMP = [...giftCardsRequests];
			const requestGiftCard = giftCardsRequests.find(({ id }) => {
				return id == requestId;
			});
			const index = giftCardsRequestsTMP.indexOf(requestGiftCard);
			giftCardsRequestsTMP.splice(index, 1);
			setGiftCardsRequests(giftCardsRequestsTMP);
		} else {
		}
	}
	return (
		<>
			<button type="button" className="btn-no-fill" style={{ width: "20%" }} onClick={() => setShow(!show)}>
				<h3>{show ? "Hide" : "Show"} Giftcard requests</h3>
			</button>
			<div style={{ display: show ? "block" : "none" }}>
				<h1>Giftcard requests : </h1>
				<Grid container>
					<Grid item xs={12} sx={{ overflowX: "auto", margin: "2em" }}>
						{giftCardsRequests != null && giftCardsRequests !== undefined && giftCardsRequests.length !== 0 && (
							<>
								<table>
									<thead>
										<tr>
											<th>
												<h5>CREATION DATE</h5>
											</th>
											<th>
												<h5>/giftCardRequests/{"{id}"}</h5>
											</th>
											<th>
												<h5>USER</h5>
											</th>
											<th>
												<h5>GIFT CARD</h5>
											</th>
											<th>
												<h5>STATUS</h5>
											</th>
											<th>
												<h5>DELETE REQUEST</h5>
											</th>
										</tr>
									</thead>
									<tbody>
										{giftCardsRequests.map((giftCardRequest) => {
											return (
												<tr>
													<td>
														<p className="paragraph-no-wrap">{new Date(giftCardRequest.creationDate * 1000).toUTCString()}</p>
													</td>
													<td>{giftCardRequest.id}</td>
													<td>
														<table>
															<tr>
																<td>Email: {giftCardRequest.user.email}</td>
															</tr>
															<tr>
																<td>Username: {giftCardRequest.user.username}</td>
															</tr>
															<tr>
																<td>UID: {giftCardRequest.user.uid}</td>
															</tr>
														</table>
													</td>
													<td>
														<table>
															<tr>
																<td>Amount: {giftCardRequest.giftCard.amount}</td>
															</tr>
															<tr>
																<td>Country: {giftCardRequest.giftCard.country}</td>
															</tr>
															<tr>
																<td>Currency: {giftCardRequest.giftCard.currency}</td>
															</tr>
															<tr>
																<td>Platform: {giftCardRequest.giftCard.platform}</td>
															</tr>
															<tr>
																<td>Price in bolts: {giftCardRequest.giftCard.priceInBolts}</td>
															</tr>
														</table>
													</td>
													<td>{giftCardRequest.status}</td>
													<td>
														<button onClick={() => deleteGiftCardRequest(giftCardRequest.id)}>X</button>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</>
						)}
					</Grid>
				</Grid>
			</div>
		</>
	);
};
