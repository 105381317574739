import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { Inventory } from "types/Inventory";
import { AuthContext } from "./AuthProvider";

type MarketRewardContextType = {
	itemIndex: number;
	setItemIndex: (index: number) => void;
	discountInBoltsUsed: number;
	setDiscountInBoltsUsed: (discount: number) => void;
	newPriceAfterDiscount: number;
	setNewPriceAfterDiscount: (price: number) => void;
	areBoltsEnough: boolean;
};

const MarketRewardContext = createContext<MarketRewardContextType | null>(null);

export const MarketRewardProvider = ({ reward, children }: { reward: Inventory.Reward; children: ReactNode }): JSX.Element => {
	const { profile } = useContext(AuthContext);
	const [itemIndex, setItemIndex] = useState<number>(0);
	const [discountInBoltsUsed, setDiscountInBoltsUsed] = useState(0);

	const sortedReward = useMemo(() => ({ ...reward, items: reward.items.sort((a, b) => a.price - b.price) }), [reward]);

	const [newPriceAfterDiscount, setNewPriceAfterDiscount] = useState(sortedReward.items[itemIndex].price);

	const areBoltsEnough = useMemo(() => profile.bolts >= discountInBoltsUsed, [profile.bolts, discountInBoltsUsed]);

	const data = {
		itemIndex,
		setItemIndex,
		discountInBoltsUsed,
		setDiscountInBoltsUsed,
		newPriceAfterDiscount,
		setNewPriceAfterDiscount,
		areBoltsEnough,
	};
	return <MarketRewardContext.Provider value={data}>{children}</MarketRewardContext.Provider>;
};

export const useMarketReward = () => {
	const context = useContext(MarketRewardContext);
	if (context === undefined) {
		throw new Error("useMarketReward must be used within a MarketRewardProvider");
	}
	return context;
};
