import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperType } from "swiper";
import { MarketBoxImage } from "../MarketBoxImage";
import { Inventory } from "types/Inventory";
import { useContext } from "react";
import { FirebaseContext } from "components/Providers/FirebaseProvider";
import { Navigation } from "swiper/modules";

type SwiperRewardProps = {
	reward: Inventory.Reward;
	setSelected: React.Dispatch<React.SetStateAction<number>>;
	swiper: SwiperType;
	setSwiper: React.Dispatch<React.SetStateAction<SwiperType>>;
};

export const SwiperReward = ({ reward, setSelected, swiper, setSwiper }: SwiperRewardProps) => {
	const { myStorageUrl } = useContext(FirebaseContext);
	return (
		<Swiper
			modules={[Navigation]}
			key={`swiper-rewards`}
			spaceBetween={50}
			id={`swiper-rewards`}
			slidesPerView={1}
			onInit={setSwiper}
			initialSlide={0}
			navigation
			onSlideChange={() => {
				setSelected(swiper.activeIndex || 0);
			}}>
			{reward.items.map((item) => (
				<SwiperSlide
					key={"slide-" + item.uid}
					className="next "
					style={{
						display: "flex",
						justifyContent: "center",
						width: "100%",
					}}>
					<MarketBoxImage src={`${myStorageUrl}images/market/${item.imageName || reward.imageName}`} />
				</SwiperSlide>
			))}
		</Swiper>
	);
};
