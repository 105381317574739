import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import styled from "styled-components";

const PriceButtonStyled = styled.button`
	padding: 0.7em 0;
	font-size: 1.2em;
	transition: all 0.3s ease-in-out;
	scale: ${({ active }: { active: boolean }) => (active ? 1.1 : 1)};
	background: ${({ active }: { active: boolean }) =>
		active ? "linear-gradient(90deg, #AB00C7 0.58%, #0075FF 97.84%)" : "linear-gradient(90deg, #1B1751 0.58%, #2F0A4C 97.84%)"};
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
`;

type PriceButtonProps = {
	active: boolean;
	sx?: SxProps<Theme>;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const PriceButton = (props: PriceButtonProps) => <Box component={PriceButtonStyled} {...props} />;
