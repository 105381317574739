import { createContext, useContext } from "react";

import config from "../../config-file.json";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { SettingsContext } from "./SettingsProvider";
import { getToken, initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { FirebaseContext } from "./FirebaseProvider";
import { FirebaseProviderData, NetworkProviderData, SettingsProviderData } from "../../types/Providers";

export const NetworkContext = createContext<NetworkProviderData | null>(null);
export const NetworkProvider = (props): JSX.Element => {
	const children = props.children;

	const baseUrl = config[process.env.REACT_APP_ENV]["url-backend"];
	const { language } = useContext(SettingsContext) as SettingsProviderData;
	const header: AxiosRequestConfig = { headers: {}, withCredentials: true };
	const { myApp } = useContext(FirebaseContext) as FirebaseProviderData;

	const appCheck = initializeAppCheck(myApp, {
		provider: new ReCaptchaEnterpriseProvider(config[process.env.REACT_APP_ENV]["recaptcha-key"]),
		isTokenAutoRefreshEnabled: true,
	});

	async function makePostCall<T = any, D = any>(url: string, body: any, options?: Object): Promise<AxiosResponse<T, D>> {
		if (url === undefined) {
			console.error("url param is undefined in makePost function");
			return;
		}
		console.log(`POST ${url}`);
		console.log(`Body ${body}`);
		let { token } = await getToken(appCheck, false);
		try {
			header.headers["X-Firebase-AppCheck"] = token;
			console.log(body, header);
			let finalUrl = baseUrl.concat(url);
			if (url.includes("?_token=")) {
				finalUrl = `${baseUrl}${url}&language=${language}`;
			} else {
				finalUrl += `?language=${language}`;
			}
			const result = await axios.post<T>(finalUrl, body, { ...header, ...options });
			return result;
		} catch (error) {
			const err = error as AxiosError<T, D>;

			return err.response as AxiosResponse<T, D>;
		}
	}

	async function makeGetCall<T = any>(path: string): Promise<AxiosResponse<T, any>> {
		if (path === undefined) {
			return;
		}
		let { token } = await getToken(appCheck, false);
		try {
			header.headers["X-Firebase-AppCheck"] = token;
			const result = await axios.get<T>(`${baseUrl.concat(path)}&language=${language}`, header);
			return result;
		} catch (error) {
			const err = error as AxiosError<T>;

			return err.response as AxiosResponse<T>;
		}
	}

	const networkProviderData: NetworkProviderData = {
		makePost: makePostCall,
		makeGet: makeGetCall,
	};

	return <NetworkContext.Provider value={networkProviderData}>{children}</NetworkContext.Provider>;
};
