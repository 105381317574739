import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid } from "@mui/material";
import { FirebaseContext } from "components/Providers/FirebaseProvider";
import { useSnackbar } from "components/Providers/SnackbarProvider";
import { doc, updateDoc } from "firebase/firestore";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { useContext } from "react";
import { Inventory } from "types/Inventory";
import { MARKET_ITEMS_TABS } from ".";

export const EditSubscriptionPanel = ({
	value,
	subscription,
	refetchNewItems,
}: {
	value: number;
	subscription: Inventory.SubscriptionItem;
	refetchNewItems: () => Promise<void>;
}): JSX.Element => {
	const { myFS } = useContext(FirebaseContext);
	const { openSnackbar } = useSnackbar();

	async function editSubscription(subscription: Inventory.SubscriptionItem): Promise<void> {
		try {
			const docRef = doc(myFS, "inventory/items");

			await updateDoc(docRef, {
				subscription: subscription,
			});

			openSnackbar("Success", "Gem Pack edited successfully", "success");
			refetchNewItems();
		} catch (error) {
			console.log(error);
			if (error) {
				openSnackbar("Error", error.message, "error");
			}
		}
	}

	async function handleSubmit(values: Inventory.SubscriptionItem, formikHelpers: FormikHelpers<Inventory.SubscriptionItem>): Promise<void> {
		console.log(values);

		// check if every field is not empty
		if (values.name === "" || values.durationInDays === 0 || values.priceInBolts === 0) {
			openSnackbar("Error", "Please fill all the fields", "error");
			return;
		}

		const subscription: Inventory.SubscriptionItem = {
			name: values.name,
			durationInDays: Number(values.durationInDays),
			priceInBolts: Number(values.priceInBolts),
			i18nKey: values.i18nKey,
		};
		await editSubscription(subscription);
	}

	return (
		<Grid container justifyContent="center" role="tabpanel" hidden={value !== MARKET_ITEMS_TABS.SUBSCRIPTION} gap={3} columns={16}>
			<Formik enableReinitialize initialValues={subscription} onSubmit={handleSubmit}>
				{({ values, setFieldValue }) => (
					<Form style={{ width: "100%" }}>
						<Grid container gap={1} flexDirection="column" alignItems="center">
							<Grid item display="flex" flexDirection="row" gap={1}>
								<Box component="label" htmlFor="name" display="flex" justifyContent="center" alignItems="center">
									<span>Name</span>
								</Box>
								<Box component={Field} name="name" placeholder="Name" type="text" required />
							</Grid>
							<Grid item display="flex" flexDirection="row" gap={1}>
								<Box component="label" htmlFor="i18nKey" display="flex" justifyContent="center" alignItems="center">
									<span>Key for translation</span>
								</Box>
								<Box component={Field} name="i18nKey" placeholder="" type="text" disabled />
							</Grid>
							<Grid item display="flex" flexDirection="row" gap={1}>
								<Box component="label" htmlFor="priceInBolts" display="flex" justifyContent="center" alignItems="center">
									<span>Price in bolts</span>
								</Box>
								<Box component={Field} name="priceInBolts" placeholder="Price in bolts" type="text" required />
							</Grid>
							<Grid item display="flex" flexDirection="row" gap={1}>
								<Box component="label" htmlFor="durationInDays" display="flex" justifyContent="center" alignItems="center">
									<span>Duration In Days</span>
								</Box>
								<Box component={Field} name="durationInDays" placeholder="VAT" type="text" required />
							</Grid>

							<Button variant="contained" color="success" type="submit" sx={{ width: "20%" }}>
								<Box display="flex" textAlign="center" justifyContent="center" alignItems="center" gap={1}>
									<FontAwesomeIcon icon={faSave as IconProp} className="fa-xl" />
									SAVE
								</Box>
							</Button>
						</Grid>
					</Form>
				)}
			</Formik>
		</Grid>
	);
};
