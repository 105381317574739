import { Timestamp } from "firebase/firestore";
import { GiftCardData, GiftCardDataDB } from "./GiftCard";
import { UserDoc } from "./User";

export type BoltsDiscount = { boltsToTake: number; boltsToEur: number };

// export module Inventory {
// 	export type Item = {
// 		boltsGiven: number;
// 		name: string;
// 		price: number;
// 		uid: number;
// 		VAT: number;
// 		category: string;
// 	};

// 	export type Items = {
// 		subscription: SubscriptionItem;
// 		gemsPack: GemsPack;
// 		giftCards: GiftCards;
// 		rewards: Reward[];
// 	};

// 	export type Reward = {
// 		service: string; // "Fortnite" | "Steam"
// 		reward: string; // "V-Bucks" | "Gift Card"
// 		description: {
// 			en: string;
// 			it: string;
// 		};
// 		image?: string;
// 		imageName?: string;
// 		items: RewardItem[];
// 	};
// 	export type RewardItem = Omit<Item, "boltsGiven" | "category"> & { priceInBolts: number; image?: string };

// 	export type ItemsName = "silverBox" | "voidBox" | "goldBox" | "galaxyBox";

// 	export type GemsPack = { [key in ItemsName]: Item };

// 	export type SubscriptionItem = {
// 		name: string;
// 		i18nKey: string;
// 		priceInBolts: number;
// 		durationInDays: number;
// 	};

// 	export type GiftCards = {
// 		amazon: GiftCardSite;
// 	};

// 	export type GiftCardSite = {
// 		[country: string]: GiftCardCountry;
// 	};

// 	export type GiftCardCountry = {
// 		amount: number[];
// 		currencyCode: string;
// 		site: string;
// 	};
// }

export module Payments {
	export module PayPal {
		export enum Status {
			COMPLETED = "completed",
			PENDING = "pending",
		}

		export type FinancialReceipt = {
			date: Timestamp;
			itemRequested: string;
			itemPrice: number;
			boltsUsed: number;
			discount: number;
			VAT: number;
			total: number;
		};

		export type Receipts = {
			completed: boolean;
			category: string;
			financialReceipt: FinancialReceipt;
			status: Status;
			user: {
				email: UserDoc["email"];
				uid: UserDoc["uid"];
				username: UserDoc["username"];
			};
		};
	}
}
