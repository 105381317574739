import { Grid } from "@mui/material";
import { useMemo, useState } from "react";
import { Inventory } from "types/Inventory";
import { SwiperReward } from "./SwiperReward";
import type { Swiper as SwiperType } from "swiper";
import { PriceButton } from "../PriceButton";
import { priceWithVAT } from "utilities/costants/genericCostants";
import { useMarketReward } from "components/Providers/MarketRewardProvider";

type RewardChoiceProps = {
	reward: Inventory.Reward;
};

export const RewardChoice = ({ reward }: RewardChoiceProps) => {
	const [swiper, setSwiper] = useState({} as SwiperType);
	const { itemIndex, setItemIndex } = useMarketReward();

	const Buttons = useMemo(
		() =>
			reward.items
				.sort((a, b) => a.price - b.price)
				.map((item, i) => (
					<Grid key={`button-${reward.reward}-${item.uid}`} item xs={11 / reward.items.length}>
						<PriceButton
							type="button"
							className="btn-gems-pack"
							active={itemIndex === i}
							onClick={() => {
								setItemIndex(i);
								swiper.slideTo(i);
							}}>
							€ {priceWithVAT(item.price)}
						</PriceButton>
					</Grid>
				)),
		[itemIndex, reward.items, reward.reward, setItemIndex, swiper]
	);

	return (
		<Grid item xs={12} md={4} xl={3}>
			<Grid container maxWidth="100%" minHeight={650} flexDirection="column" justifyContent="space-around" alignItems="center" p={0}>
				<Grid item width="100%" maxWidth="100%">
					<SwiperReward reward={reward} setSelected={setItemIndex} swiper={swiper} setSwiper={setSwiper} />
				</Grid>
				<Grid container justifyContent="space-around" flexDirection="row" alignItems="center" mb={2}>
					{Buttons}
				</Grid>
			</Grid>
		</Grid>
	);
};
