import { Box, Divider, Stack, Typography } from "@mui/material";
import { AuthContext } from "components/Providers/AuthProvider";
import { useContext, useMemo, useState } from "react";
import { Inventory } from "types/Inventory";
import { PaypalButton } from "components/DashboardComponents/Payments/PaypalButton";
import { inventoryCategories, priceWithVAT } from "utilities/costants/genericCostants";
import { ReactComponent as BoltSign } from "assets/images/Bolt_sign.svg";
import { useMarketReward } from "components/Providers/MarketRewardProvider";
import { MarketCountryFiscalCode } from "../MarketCountryFiscalCode";
import { useTranslation } from "react-i18next";

type PurchaseRecapProps = {
	reward: Inventory.Reward;
	onClose: () => void;
};

const FISCAL_CODE_REGEX = /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/;

export const PurchaseRecap = ({ reward, onClose }: PurchaseRecapProps) => {
	const { t } = useTranslation("dashboard");
	const { profile } = useContext(AuthContext);
	const [fiscalCode, setFiscalCode] = useState<string>("");
	const [countrySelected, setCountrySelected] = useState<string>("");

	const { itemIndex, discountInBoltsUsed, newPriceAfterDiscount, areBoltsEnough } = useMarketReward();

	const isFiscalCodeValid = useMemo(() => {
		return FISCAL_CODE_REGEX.test(fiscalCode);
	}, [fiscalCode]);

	const showPaypalButton = useMemo(() => {
		if (profile.fiscalCode !== undefined && areBoltsEnough) return true;
		if (countrySelected === "") return false;
		if (countrySelected !== "Italy" && areBoltsEnough) return true;
		if (countrySelected === "Italy" && isFiscalCodeValid && areBoltsEnough) return true;
		return false;
	}, [profile.fiscalCode, countrySelected, isFiscalCodeValid, areBoltsEnough]);

	const handleFiscalCode = (value: string) => {
		setFiscalCode(value);
	};

	const handleCountry = (value: string) => {
		setCountrySelected(value);
	};

	const handleOnClose = () => {
		setFiscalCode("");
		setCountrySelected("");
		onClose();
	};

	return (
		<>
			<Stack
				maxWidth={600}
				minHeight={700}
				justifyContent="space-between"
				direction="column"
				sx={{
					width: "100%",
					backgroundColor: "#1A1A1A",
					border: "1px solid #FFFFFF5A",
					borderRadius: 4,
					pt: 3,
					"& *": {
						fontFamily: "Sora",
					},
				}}>
				<Box>
					<Typography variant="h6" textTransform="uppercase" fontFamily="Sora" fontWeight={700} px={{ xs: 2, md: 7 }}>
						{t("market-page.purchase.details")}
					</Typography>
					<Typography variant="h4" textAlign="center" textTransform="capitalize" fontWeight={500} mt={1.5}>
						{reward.reward}
					</Typography>
					<Divider
						orientation="horizontal"
						sx={{
							borderColor: "#FFFFFF5A",
							opacity: 1,
							my: 2,
						}}
					/>
					<Stack direction="column" px={{ xs: 3, md: 7 }}>
						<Typography variant="h5" fontFamily="Sora" textAlign="center">
							{reward.service}
						</Typography>

						<Divider
							orientation="horizontal"
							sx={{
								borderColor: "#FFFFFF5A",
								opacity: 1,
								my: 2,
							}}
						/>

						{reward.reward.toLowerCase().trim() !== reward.items[itemIndex].name.toLowerCase().trim() && (
							<>
								<Stack direction="row" justifyContent="space-between">
									<Typography variant="h6" fontFamily="Sora" textTransform="capitalize">
										{t("market-page.product")}
									</Typography>
									<Typography variant="h5" fontFamily="Sora">
										{reward.items[itemIndex].name}
									</Typography>
								</Stack>

								<Divider
									orientation="horizontal"
									sx={{
										borderColor: "#FFFFFF5A",
										opacity: 1,
										my: 2,
									}}
								/>
							</>
						)}

						<Stack direction="row" justifyContent="space-between">
							<Typography variant="h6" fontFamily="Sora" textTransform="capitalize">
								{t("market-page.price")}
							</Typography>
							<Typography variant="h5" fontFamily="Sora">
								€ {priceWithVAT(newPriceAfterDiscount)}
							</Typography>
						</Stack>

						{discountInBoltsUsed > 0 && (
							<>
								<Divider
									orientation="horizontal"
									sx={{
										borderColor: "#FFFFFF5A",
										opacity: 1,
										my: 2,
									}}
								/>

								<Stack direction="row" justifyContent="space-between">
									<Typography variant="h6" fontFamily="Sora" textTransform="capitalize">
										{t("market-page.discount")}
									</Typography>
									<Typography variant="h5" fontFamily="Sora">
										- € {priceWithVAT(reward.items[itemIndex].price - newPriceAfterDiscount)}
									</Typography>
								</Stack>

								<Divider
									orientation="horizontal"
									sx={{
										borderColor: "#FFFFFF5A",
										opacity: 1,
										my: 2,
									}}
								/>

								<Stack direction="row" justifyContent="space-between">
									<Typography variant="h6" fontFamily="Sora" textTransform="capitalize">
										{t("market-page.bolts.used")}
									</Typography>
									<Stack direction="row" gap={1} alignItems="center">
										<Typography variant="h5" fontFamily="Sora" color={!areBoltsEnough && "error.main"} fontWeight={areBoltsEnough ? 400 : 700}>
											{discountInBoltsUsed}
										</Typography>
										<Box component={BoltSign} fill={areBoltsEnough ? "white" : "red"} width="1.5em" />
									</Stack>
								</Stack>
							</>
						)}
					</Stack>

					<Divider
						orientation="horizontal"
						sx={{
							borderColor: "#FFFFFF5A",
							opacity: 1,
							my: 2,
						}}
					/>

					{profile.fiscalCode === undefined && (
						<MarketCountryFiscalCode
							countrySelected={countrySelected}
							fiscalCode={fiscalCode}
							handleCountry={handleCountry}
							handleFiscalCode={handleFiscalCode}
						/>
					)}
				</Box>
				{!showPaypalButton && (
					<Stack flexGrow={1} direction="column" justifyContent="center" alignItems="center" spacing={3} px={2} py={5}>
						{profile.fiscalCode === undefined && countrySelected === "" && (
							<Typography variant="h5" fontFamily="Sora" textAlign="center">
								{t("market-page.invalid.country")}
							</Typography>
						)}
						{profile.fiscalCode === undefined && countrySelected === "Italy" && !isFiscalCodeValid && (
							<Typography variant="h5" fontFamily="Sora" textAlign="center">
								{t("market-page.invalid.fiscal-code")}
							</Typography>
						)}
						{!areBoltsEnough && (
							<Typography variant="h5" fontFamily="Sora" color="error.main" fontWeight={700} textAlign="center">
								{t("market-page.bolts.not-enough")}
							</Typography>
						)}
					</Stack>
				)}
				{showPaypalButton && (
					<Box
						sx={{
							width: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "column",
							flexGrow: 1,
							px: { xs: 2, md: 8 },
							py: 3,
						}}>
						<PaypalButton
							cart={{
								uid: reward.items[itemIndex].uid,
								name: reward.reward,
								category: inventoryCategories.REWARDS,
							}}
							onSuccess={handleOnClose}
							boltsUsedForDiscount={discountInBoltsUsed}
							fiscalCode={profile.fiscalCode || fiscalCode}
						/>
					</Box>
				)}
			</Stack>
		</>
	);
};
