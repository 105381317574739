import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Grid,
	Stack,
	Tooltip,
	Typography,
	styled,
	TooltipProps,
	tooltipClasses,
	useTheme,
	useMediaQuery,
} from "@mui/material";
import { SettingsContext } from "components/Providers/SettingsProvider";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Inventory } from "types/Inventory";
import DOMPurify from "dompurify";
import { CustomSlider } from "components/Slider/CustomSlider";
import { MarketContext } from "components/Providers/MarketProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ReactComponent as BoltSign } from "assets/images/Bolt_sign.svg";
import { AuthContext } from "components/Providers/AuthProvider";
import { priceWithVAT } from "utilities/costants/genericCostants";
import { useMarketReward } from "components/Providers/MarketRewardProvider";

type DescriptionDetailsProps = {
	reward: Inventory.Reward;
};

const calculateDiscount = (sliderValue: number, itemPriceInBolts: number): number => {
	const discountAppliedToEveryStepOfSlider = 5;
	const fivePercentOfPrice = itemPriceInBolts * 0.05;
	const discountInBolts = fivePercentOfPrice * (sliderValue / discountAppliedToEveryStepOfSlider);
	return Number(discountInBolts.toFixed(6));
};

const STEP_SLIDER = 1;
const MIN_VALUE_SLIDER = {
	value: 50,
	label: "50%",
};
const MAX_VALUE_SLIDER = {
	value: 90,
	label: "90%",
};
/**
 * Calculate the number of steps for the slider
 * @example
 * // The slider goes from 50 to 90 with a step of 5
 * // So the number of steps are:
 * NUMBER_OF_STEPS = (90 - 50 - 5) / 5; // == 7
 * // --> 55, 60, 65, 70, 75, 80, 85
 */
const NUMBER_OF_STEPS = (MAX_VALUE_SLIDER.value - MIN_VALUE_SLIDER.value - STEP_SLIDER) / STEP_SLIDER;

/**
 * Create an array of objects `{value: number}` from `MIN_VALUE_SLIDER` to `MAX_VALUE_SLIDER` with a step of `STEP_SLIDER`
 *
 * This is used to create the marks for the slider
 *
 * Since the slider has 0 as the first value and then he goes from 50 to 90 with a step of `STEP_SLIDER`, we need to create a marks array that goes from 50 to 90 with a step of `STEP_SLIDER`
 */
const STEP_VALUE_SLIDER = Array.from({ length: NUMBER_OF_STEPS }, (_, i) => i + 1).map((value) => ({
	value: value * STEP_SLIDER + MIN_VALUE_SLIDER.value,
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps): JSX.Element => <Tooltip {...props} classes={{ popper: className }} />)(
	({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			background: "rgba(0,0,0,1)",
			color: "rgba(255, 255, 255, 0.87)",
			maxWidth: 300,
			minWidth: 110,
			fontSize: theme.typography.pxToRem(12),
			borderRadius: 10,
			border: "1px solid #FFFFFF5A",
		},
	})
);

export const DescriptionDetails = ({ reward }: DescriptionDetailsProps) => {
	const { t } = useTranslation("dashboard");
	const { profile } = useContext(AuthContext);
	const { language } = useContext(SettingsContext);
	const { canUserApplyDiscount } = useContext(MarketContext);
	const { itemIndex, areBoltsEnough, discountInBoltsUsed, setDiscountInBoltsUsed, newPriceAfterDiscount, setNewPriceAfterDiscount } =
		useMarketReward();

	const [readMoreInfo, setReadMoreInfo] = useState(false);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const toggleReadMoreInfo = () => setReadMoreInfo((prev) => !prev);

	const onChangeSlider = (_: Event, value: number | number[]) => {
		const discount = calculateDiscount(value as number, reward.items[itemIndex].priceInBolts);
		setDiscountInBoltsUsed(discount);
		if (profile.bolts >= discount) {
			const price = Math.round(reward.items[itemIndex].price * (1 - (value as number) / 100));
			setNewPriceAfterDiscount(price);
		}
	};

	return (
		<>
			<Grid item alignSelf={{ xs: "center", md: "flex-start" }} xs={12} md={5} xl={4} mb={{ xs: 2, md: 0 }}>
				<Typography variant="subtitle1" textTransform="capitalize" fontFamily="Sora" pt={0}>
					[{reward.service}]
				</Typography>
				<Typography variant="h4" fontWeight="bold" textTransform="capitalize" fontFamily="Sora">
					{reward.reward}
				</Typography>
				{isMobile && (
					<>
						<br />
						<Typography
							variant="body1"
							fontFamily="Sora"
							fontWeight={500}
							onClick={toggleReadMoreInfo}
							sx={{
								cursor: "pointer",
								border: "1px solid white",
								borderRadius: "1000px",
								px: 2,
								py: 1,
								width: "fit-content",
								backgroundImage: "linear-gradient(90deg, #AB00C7 0.58%, #0075FF 97.84%)",
								backgroundClip: "text",
								WebkitBackgroundClip: "text",
								WebkitTextFillColor: "transparent",
								color: "transparent",
							}}>
							{readMoreInfo ? t("market-page.read.less") : t("market-page.read.more")}{" "}
							<FontAwesomeIcon
								icon={faAngleDown as IconProp}
								color="#0075FF"
								// size="1x"
								className="fa-lg"
								transform={{
									rotate: readMoreInfo ? 180 : 0,
								}}
							/>
						</Typography>
					</>
				)}

				{(!isMobile || readMoreInfo) && (
					<>
						<br />
						<Typography
							variant="h5"
							fontFamily="Sora"
							maxHeight={{
								xs: 300,
								md: "auto",
							}}
							overflow="scroll"
							dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(reward.description[language]) }}
						/>
					</>
				)}
				<br />
				<Typography variant="h3" fontFamily="Sora">
					€ {priceWithVAT(newPriceAfterDiscount)}
				</Typography>
				<br />
				<Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
					<Accordion
						disabled={!canUserApplyDiscount}
						sx={{
							width: "100%",
							minWidth: 200,
							background: "linear-gradient(270deg, #7100A74D 0%, #F07EFA4D 100%);",
							border: "none",
						}}>
						<AccordionSummary
							expandIcon={<FontAwesomeIcon icon={faAngleDown as IconProp} color="white" size="2x" />}
							sx={{
								px: 2,
								py: 1,
							}}>
							{t("market-page.bolts.discount")}
						</AccordionSummary>
						<AccordionDetails
							sx={{
								px: { xs: 2, md: 7 },
							}}>
							{areBoltsEnough ? (
								<Stack direction={{ xs: "column", xl: "row" }} justifyContent="space-between" mb={4} minWidth={200}>
									<Stack direction="row" gap={1}>
										<Typography variant="h6" fontFamily="Sora" textTransform="capitalize">
											{t("market-page.discount")}:
										</Typography>
										<Typography variant="h5" fontFamily="Sora">
											{priceWithVAT(reward.items[itemIndex].price - newPriceAfterDiscount)} €
										</Typography>
									</Stack>
									<Stack direction="row" gap={1} alignItems="center">
										<Stack direction="row" gap={1}>
											<Typography variant="h6" fontFamily="Sora" textTransform="capitalize">
												{t("market-page.bolts.used")}:
											</Typography>
											<Typography variant="h5" fontFamily="Sora">
												{discountInBoltsUsed}
											</Typography>
										</Stack>
										<Box component={BoltSign} fill="white" width="1.5em" />
									</Stack>
								</Stack>
							) : (
								<Typography variant="h6" fontFamily="Sora" mb={4} color="error.main" fontWeight={700} textAlign="right">
									{t("market-page.bolts.not-enough")}
								</Typography>
							)}
							<CustomSlider
								disabled={!canUserApplyDiscount}
								ariaLabel="Bolts"
								defaultValue={0}
								onChange={onChangeSlider}
								min={0}
								max={MAX_VALUE_SLIDER.value}
								marks={[
									{
										value: 0,
										label: "0%",
									},
									{ ...MIN_VALUE_SLIDER },
									...STEP_VALUE_SLIDER,
									{ ...MAX_VALUE_SLIDER },
								]}
							/>
						</AccordionDetails>
					</Accordion>
					<HtmlTooltip
						hidden={canUserApplyDiscount}
						title={<Typography variant="body1">{t("market-page.purchase.full-price")}</Typography>}
						arrow
						placement="left">
						<div>
							<Box
								component={FontAwesomeIcon}
								icon={faCircleQuestion as IconProp}
								color="white"
								size="2x"
								sx={{
									cursor: "help",
									":hover": {
										color: "primary.main",
									},
								}}
							/>
						</div>
					</HtmlTooltip>
				</Stack>
			</Grid>
		</>
	);
};
