import { useContext, useEffect, useState } from "react";
import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FooterLanding } from "../home/landing/FooterLanding";
import { Inventory } from "../../types/Inventory";
import { MarketContext } from "../Providers/MarketProvider";
import { FirebaseContext } from "../Providers/FirebaseProvider";
import { MarketMainCard } from "./Market/MarketMainCard";
import "../../styles/DropDown.scss";
import "../../styles/DashboardMarket.scss";
import "react-widgets/styles.css";

import GalaxyBoxImage from "../../assets/images/galaxy_box.svg";
import { useTranslation } from "react-i18next";
import { UnauthorizedDialog } from "./UnauthorizedDialog";
import { AuthContext } from "components/Providers/AuthProvider";
import { MarketGemDialog } from "./Market/GemsPacks";
import { MarketRewardDialog } from "./Market/Rewards";
import DOMPurify from "dompurify";
import { MarketRewardProvider } from "components/Providers/MarketRewardProvider";

declare module "@mui/material/styles" {
	interface BreakpointOverrides {
		xs: true;
		sm: true;
		tablemedium: true;
		md: true;
		lgsmall: true;
		lg: true;
		largemedium: true;
		xl: true;
		xxl: true;
		xxxl: true;
	}
}

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			tablemedium: 800,
			md: 900,
			lgsmall: 1000,
			lg: 1200,
			largemedium: 1350,
			xl: 1536,
			xxl: 1900,
			xxxl: 2500,
		},
	},
});

const TOP_REWARDS_TO_SHOW = 3;

export const DashboardMarket = ({ name }: { name?: string }): JSX.Element => {
	const { getItemsFromDB } = useContext(MarketContext);
	const { myStorageUrl } = useContext(FirebaseContext);
	const { profile } = useContext(AuthContext);
	const { t, i18n } = useTranslation("dashboard");

	const [items, setItems] = useState<Inventory.Items>(undefined);

	const [isUnauthorizedDialogOpen, setIsUnauthorizedDialogOpen] = useState<boolean>(false);
	const [openGemsDialog, setOpenGemsDialog] = useState(false);
	const [openRewardsDialog, setOpenRewardsDialog] = useState(false);
	const [selectedReward, setSelectedReward] = useState<number>(0);

	useEffect(() => {
		(async () => {
			const items = await getItemsFromDB();
			setItems(items);
		})();
	}, [getItemsFromDB]);

	const handleOpenGemsDialog = () => {
		if (!profile) setIsUnauthorizedDialogOpen(true);
		else setOpenGemsDialog(true);
	};
	const handleOpenRewardsDialog = (index: number) => {
		if (!profile) setIsUnauthorizedDialogOpen(true);
		else {
			setSelectedReward(index);
			setOpenRewardsDialog(true);
		}
	};

	return (
		<>
			<UnauthorizedDialog open={isUnauthorizedDialogOpen} handleClose={() => setIsUnauthorizedDialogOpen(false)} />

			{profile && items !== undefined && (
				<MarketGemDialog gemsPack={items.gemsPack} openGemsDialog={openGemsDialog} setOpenGemsDialog={setOpenGemsDialog} />
			)}

			{profile && items !== undefined && items.rewards.length > 0 && (
				<MarketRewardProvider reward={items.rewards[selectedReward]}>
					<MarketRewardDialog
						key={items.rewards[selectedReward].reward}
						reward={items.rewards[selectedReward]}
						openRewardDialog={openRewardsDialog}
						setOpenRewardDialog={setOpenRewardsDialog}
					/>
				</MarketRewardProvider>
			)}

			<div className="market-main">
				<ThemeProvider theme={theme}>
					<Grid
						container
						display="grid"
						gridTemplateRows={{ xs: "1fr", largemedium: "auto auto" }}
						gridTemplateColumns={{ xs: "1fr", largemedium: "1fr 1fr" }}
						gap={{ xs: 3, sm: 5 }}
						width={{ xs: "100%", lg: "60%" }}
						mx="auto"
						py={8}>
						{items === undefined ? (
							<>
								<Skeleton variant="rectangular" width="100%" height="300px" sx={{ bgcolor: "grey.700", borderRadius: "30px" }} animation={"pulse"} />
								<Skeleton variant="rectangular" width="100%" height="300px" sx={{ bgcolor: "grey.700", borderRadius: "30px" }} animation={"pulse"} />
								<Skeleton variant="rectangular" width="100%" height="300px" sx={{ bgcolor: "grey.700", borderRadius: "30px" }} animation={"pulse"} />
								<Skeleton variant="rectangular" width="100%" height="300px" sx={{ bgcolor: "grey.700", borderRadius: "30px" }} animation={"pulse"} />
							</>
						) : (
							<>
								<MarketMainCard
									imageSrc={GalaxyBoxImage}
									handleBuy={() => {
										handleOpenGemsDialog();
									}}>
									<Stack px={{ xs: 2, md: 7 }}>
										<Typography variant="h6" fontFamily="Sora" whiteSpace="break-spaces">
											[Gem Pack]
										</Typography>
										<Typography variant="h5" fontSize="200%" fontFamily="Sora" whiteSpace="break-spaces" fontWeight={700} textTransform="capitalize">
											{items.gemsPack.galaxyBox.name}
										</Typography>
										<br />
										<Box
											component="div"
											fontSize="body1.fontSize"
											fontFamily="Sora"
											fontWeight={400}
											sx={{
												display: "-webkit-box",
												WebkitBoxOrient: "vertical",
												WebkitLineClamp: 3,
												overflow: "hidden",
											}}>
											{t("purchase bolts to access eStorm services")}
										</Box>
									</Stack>
								</MarketMainCard>
								{items.rewards &&
									items.rewards.length > 0 &&
									items.rewards.slice(0, TOP_REWARDS_TO_SHOW).map((reward, index) => (
										<MarketMainCard
											imageSrc={`${myStorageUrl}images/market/${reward.items[0].imageName || reward.imageName}`}
											key={`${reward.service}-${reward.reward}-${index}`}
											handleBuy={() => {
												handleOpenRewardsDialog(index);
											}}>
											<Stack px={{ xs: 2, md: 7 }}>
												<Typography variant="h6" fontFamily="Sora" whiteSpace="break-spaces">
													[{reward.service}]
												</Typography>
												<Typography
													variant="h5"
													fontSize="200%"
													fontFamily="Sora"
													whiteSpace="break-spaces"
													textTransform="capitalize"
													fontWeight={700}>
													{reward.reward}
												</Typography>
												<br />
												<Box
													component="div"
													fontSize="body1.fontSize"
													fontFamily="Sora"
													fontWeight={400}
													sx={{
														lineHeight: 2,
														display: "-webkit-box",
														WebkitBoxOrient: "vertical",
														WebkitLineClamp: 3,
														overflow: "hidden",
													}}
													dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(reward.description[i18n.language]) }}
												/>
											</Stack>
										</MarketMainCard>
									))}
							</>
						)}
					</Grid>
				</ThemeProvider>
				<div style={{ position: "relative", marginBottom: "2em" }}>
					<FooterLanding />
				</div>
			</div>
		</>
	);
};
