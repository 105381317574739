import { useContext, useState } from "react";

import { Button, Grid, Typography } from "@mui/material";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Inventory } from "types/Inventory";
import { EditDialog } from ".";

import { ReactComponent as SilverBox } from "assets/images/silver_box.svg";
import { ReactComponent as VoidBox } from "assets/images/void_box.svg";
import { ReactComponent as GoldBox } from "assets/images/gold_box.svg";
import { ReactComponent as GalaxyBox } from "assets/images/galaxy_box.svg";
import { MARKET_ITEMS_TABS } from "..";
import { FirebaseContext } from "components/Providers/FirebaseProvider";

const BOXES = { silverBox: SilverBox, voidBox: VoidBox, goldBox: GoldBox, galaxyBox: GalaxyBox } as const;

export const EditGemsPackPanel = ({
	value,
	gemsPacks,
	refetchNewItems,
}: {
	value: number;
	gemsPacks: Inventory.GemsPack;
	refetchNewItems: () => Promise<void>;
}): JSX.Element => {
	const { myStorageUrl } = useContext(FirebaseContext);
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [editGemPack, setEditGemPack] = useState<Inventory.Item | null>(null);
	const [editGemPackKey, setEditGemPackKey] = useState<Inventory.GemsPacksName | null>(null);

	const gemsPacksArray = Object.entries(gemsPacks).sort((a, b) => a[1].boltsGiven - b[1].boltsGiven);

	return (
		<Grid container justifyContent="center" role="tabpanel" hidden={value !== MARKET_ITEMS_TABS.GEMS_PACKS} gap={3} columns={16}>
			<EditDialog
				open={openEditDialog}
				onClose={() => setOpenEditDialog(false)}
				gemPack={editGemPack}
				gemPackKey={editGemPackKey}
				Image={BOXES[editGemPackKey]}
				refetchNewItems={refetchNewItems}
			/>
			{gemsPacksArray.map(([gemPackKey, gemPack], index) => {
				const Image = BOXES[gemPackKey as Inventory.GemsPacksName];
				return (
					<Grid
						item
						key={`${gemPackKey}-${index}`}
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="space-between"
						gap={3}
						xs={3}
						sx={{
							border: "1px solid white",
							borderRadius: 5,
							py: 2,
							px: 2,
						}}>
						<Typography variant="h4">{gemPack.name}</Typography>
						<Typography variant="h5">
							{gemPack.boltsGiven} <img src={`${myStorageUrl}images/bolt_sign.png`} id="bolt-sign-gamerbox" className="bolt-sign" alt="bolt_sign" />
						</Typography>
						<Image title={gemPackKey} width="250px" height="250px" />

						<Grid item display="flex" justifyContent="center" gap={1}>
							<Button
								type="button"
								sx={{
									display: "flex",
									alignItems: "center",
									gap: 1,
									backgroundColor: "success.main",
									color: "white",
									border: 0,
									borderRadius: 2,
									boxShadow: 1,
									cursor: "pointer",
									fontSize: 16,
									fontWeight: "bold",
									lineHeight: 1.5,
									padding: "10px 20px",
									margin: "10px",
									"&:hover": {
										backgroundColor: "success.dark",
									},
								}}
								onClick={() => {
									setEditGemPack(gemPack);
									setEditGemPackKey(gemPackKey as Inventory.GemsPacksName);
									setOpenEditDialog(true);
								}}>
								<FontAwesomeIcon icon={faEdit as IconProp} className="fa-xl" /> EDIT
							</Button>
						</Grid>
					</Grid>
				);
			})}
		</Grid>
	);
};
