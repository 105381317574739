import config from "../../config-file.json";
import {
	browserSessionPersistence,
	createUserWithEmailAndPassword,
	onAuthStateChanged,
	sendEmailVerification,
	setPersistence,
	signInWithCustomToken,
	signInWithEmailAndPassword,
	signOut,
	User,
} from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { fortniteAuthAPI, signupAPI, steamAuthAPI, uploadImageAPI, validateCodeAPI, validateSignupAPI } from "../../utilities/network/api";
import { FirebaseContext } from "./FirebaseProvider";
import { collection, doc, onSnapshot, Timestamp, updateDoc, getDoc } from "firebase/firestore";
import { SettingsContext } from "../Providers/SettingsProvider";
import { NetworkContext } from "./NetworkProvider";
import { AuthProviderData, FirebaseProviderData, NetworkProviderData, SettingsProviderData } from "../../types/Providers";
import { DailyQuest, DailyUserQuest, DailyUserQuestDBData, MonthlyQuest, MonthlyUserQuest, MonthlyUserQuestDBData } from "../../types/Quest";
import { Profile, UserDoc, UserSingInEmailAndPassword, UserSinginGoogle } from "../../types/User";
import { DBNotification, UserNotification } from "../../types/Notification";
import {
	ChangeImageAPIResponse,
	FortniteAuthAPIResponse,
	SignupAPIResponse,
	ValidateCodeAPIResponse,
	ValidateSignupAPIResponse,
} from "../../types/APIResponse";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export const AuthContext = createContext<AuthProviderData | null>(null);
export const AuthProvider = (props: any): JSX.Element => {
	const children = props.children;
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState(null as User);
	const [profile, setProfile] = useState(null as Profile);
	const [authLoading, setAuthLoading] = useState(true);
	const [lastNotificationReceveid, setLastNotificationReceived] = useState(null as UserNotification);
	const [authErrorMessage, setAuthErrorMessage] = useState("");
	const [notifications, setNotifications] = useState([] as UserNotification[]);
	const [dailyQuests, setDailyQuests] = useState([] as DailyUserQuest[]);
	const [monthlyQuests, setMonthlyQuests] = useState([] as MonthlyUserQuest[]);
	const navigate = useNavigate();
	const { search } = useLocation();

	const { language } = useContext(SettingsContext) as SettingsProviderData;
	const { myAuth, myFS } = useContext(FirebaseContext) as FirebaseProviderData;
	const { makePost, makeGet } = useContext(NetworkContext) as NetworkProviderData;
	const { t } = useTranslation("responses");

	const loginFunction = async (email: string, password: string): Promise<boolean> => {
		let userCredential = await signInWithEmailAndPassword(myAuth, email, password);
		let user = userCredential.user;

		if (!user?.uid) {
			let msg = `No UID found after signIn!`;
			console.error(msg);
			return false;
		}
		setUser(user);
		return true;
	};

	const registerFunction = async (user: UserSingInEmailAndPassword): Promise<boolean> => {
		setLoading(true);
		let userCreatedSuccessfully = false;
		if (!user) {
			console.error("No user data passed in registerFunction");
			setLoading(false);
			return false;
		}
		try {
			const userCreated = await createUserWithEmailAndPassword(myAuth, user.email, user.password);
			user.uid = userCreated.user.uid;
			userCreatedSuccessfully = (await makePost<SignupAPIResponse>(signupAPI, user)).data.message === "User created successfully" ? true : false;

			if (!myAuth.currentUser) {
				console.error("Current user is undefined");
				setLoading(false);
				return false;
			}

			await sendEmailVerification(myAuth.currentUser, {
				url: config[process.env.REACT_APP_ENV]["url-used-to-send-mail-auth-signup"],
				handleCodeInApp: true,
			});
		} catch (error) {
			setLoading(false);
			if (error.code === "auth/email-already-in-use") {
				throw {
					code: "auth/email-already-in-use",
					message: "email already used",
				};
			}
		}
		setLoading(false);
		return userCreatedSuccessfully;
	};

	const createUserAfterGoogleSignIn = async (user: UserSinginGoogle): Promise<boolean> => {
		setLoading(true);

		const res = await makePost<SignupAPIResponse>(signupAPI, user);
		const userCreated = res.data.message === "OK" ? true : false;
		setAuthLoading(false);
		setLoading(false);
		return userCreated;
	};

	const checkIfUsernameIsTaken = async (username: string): Promise<boolean> => {
		if (!username) {
			console.error("Username passed is undefined");
		}
		const res = await makeGet<ValidateSignupAPIResponse>(`${validateSignupAPI}?username=${username}`);
		return res.data.documentAlreadyExists;
	};

	const validateCode = async (codeToValidate: string): Promise<{ isCodeValid: boolean; message: string }> => {
		setLoading(true);
		let isCodeValid = true;
		if (!codeToValidate) {
			console.error("Code to validate is undefined");
			setLoading(false);
			return;
		}
		const res = await makeGet<ValidateCodeAPIResponse>(`${validateCodeAPI}?code=${codeToValidate}`);
		let messageKey = res.data.i18nKey;
		let message = messageKey != null ? t(messageKey) : res.data.message;
		if (res.status === 200) {
			if (!res.data.code.active) {
				isCodeValid = false;
				message = message;
			}
		} else {
			isCodeValid = false;
			message = message;
		}
		setLoading(false);
		return { isCodeValid, message };
	};

	async function sendVerification(): Promise<void> {
		if (!profile.emailVerified) {
			await sendEmailVerification(myAuth.currentUser, {
				url: config[process.env.REACT_APP_ENV]["url-used-to-send-mail-auth-signup"],
				handleCodeInApp: true,
			});
		}
	}

	const logoutFunction = async (): Promise<boolean> => {
		setUser(null);
		await signOut(myAuth);
		return true;
	};

	const connectSteam = async (): Promise<string> => {
		const res = await makePost<string>(steamAuthAPI, {
			_token: `Bearer ${profile.idToken}`,
		});
		return res.data;
	};

	const connectEpicGames = async (code: string): Promise<void> => {
		try {
			await makePost<FortniteAuthAPIResponse>(fortniteAuthAPI, {
				_token: `Bearer ${profile.idToken}`,
				code: code,
			});
			window.location.href = "/dashboard";
		} catch (e) {
			console.log(e.message);
		}
	};
	const changeImage = async (formData: FormData): Promise<string> => {
		const res = await makePost<ChangeImageAPIResponse>(`${uploadImageAPI}?_token=${profile.idToken}&language=${language}`, formData);
		return res.data.message;
	};
	const persistanceSet = async (): Promise<void> => {
		await setPersistence(myAuth, browserSessionPersistence);
	};

	const handleCustomToken = async () => {
		const params = new URLSearchParams(search);
		const token = params.get("token");
		const page = params.get("page");

		// need to use the language from the local storage
		// because useTranslation hook does not work
		const language = window.localStorage.getItem("language");
		toast.loading(
			<h6>
				{language === "it" ? "Stai per essere reindirizzato alla pagina " : "You are about to be redirected to the "}
				{page}
				{language === "en" ? " page" : ""}...
			</h6>
		);
		if (token) {
			const userCredentials = await signInWithCustomToken(myAuth, token);

			// removing token from url
			params.delete("token");

			setUser(userCredentials.user);

			// replacing the url without the token & redirecting to the page
			window.location.replace(window.location.pathname + "?" + params.toString());
		} else toast.dismiss();
	};

	// hook into Firebase Authentication
	useEffect(() => {
		if (myAuth) {
			let unsubscribe = onAuthStateChanged(myAuth, async (user) => {
				if (user != null) {
					persistanceSet();

					setUser(user);
					const validUrl = ["http://localhost:3000/", "https://storm.co.gg/", "https://stormdevelopment-64ea9.web.app/"];
					if (
						window.location.href.includes("/login") ||
						window.location.href.includes("/signup") ||
						window.location.href.includes("/howitworks") ||
						validUrl.includes(window.location.href)
					) {
						persistanceSet();
						navigate("/dashboard");
					}
				} else {
					persistanceSet();
					if (!window.location.href.includes("/login") && window.location.href.includes("/dashboard")) {
						await handleCustomToken();
						navigate("/login");
					}
				}
				setAuthLoading(false);
			});
			return unsubscribe && unsubscribe();
		}
	}, [myAuth]);

	useEffect(() => {
		if (user != null) {
			let unsubscribe = null;
			const listenToNotificationsOfUser = (uid: string) => {
				try {
					const collRef = collection(myFS, `users/${uid}/notifications`);

					unsubscribe = onSnapshot(collRef, async (querySnap) => {
						if (querySnap.empty) setNotifications([]);

						let notificationsToPush = [];
						const currentDate = new Date();

						for (let i = 0; i < querySnap.docs.length; i++) {
							const notificationData: DBNotification = querySnap.docs[i].data() as DBNotification;

							const timestamp = new Timestamp(notificationData.creationDate.seconds, notificationData.creationDate.nanoseconds);
							const notificationDate = new Date(timestamp.toDate());

							const secondsPassed = Math.round(Math.abs((notificationDate.valueOf() - currentDate.valueOf()) / 1000));

							// convert seconds to days, hours and minutes
							const daysPassed = Math.floor(secondsPassed / (3600 * 24));
							const hoursPassed = Math.floor((secondsPassed % (3600 * 24)) / 3600);
							const minutesPassed = Math.floor(((secondsPassed % (3600 * 24)) % 3600) / 60);

							let notification: UserNotification = {
								id: querySnap.docs[i].id,
								defaultMessage: notificationData.message,
								message: notificationData.i18nKey,
								time: {
									date: daysPassed,
									hours: hoursPassed,
									minutes: minutesPassed,
								},
								read: notificationData.read,
							};

							notificationsToPush.push(notification);
						}

						notificationsToPush.sort((n1, n2) => {
							if (n1.time.date !== n2.time.date) return n1.time.date - n2.time.date;

							if (n1.time.hours !== n2.time.hours) return n1.time.hours - n2.time.hours;

							if (n1.time.minutes !== n2.time.minutes) return n1.time.minutes - n2.time.minutes;

							return 0;
						});
						setNotifications([...notificationsToPush]);
					});
				} catch (ex) {
					console.error(`useEffect() failed with: ${ex.message}`);
					setAuthErrorMessage(ex.message);
				}
			};

			if (user?.uid) {
				listenToNotificationsOfUser(user.uid);

				return () => {
					unsubscribe && unsubscribe();
				};
			} else if (!user) {
				setAuthLoading(true);
				setLastNotificationReceived(null);
				setAuthErrorMessage(null);
			}
		}
	}, [user, myFS]);

	useEffect(() => {
		if (user != null) {
			let unsubscribe = null;
			let unsubscribe_ = null;
			let unsubscribeDailyQuests = null;
			let unsubscribeMonthlyQuests = null;
			let interval = null;

			const listenToUserDoc = async (uid: string) => {
				try {
					let docRef = doc(myFS, "users", uid);
					unsubscribe = onSnapshot(docRef, async (docSnap) => {
						const userData = { ...docSnap.data(), uid: docSnap.id } as UserDoc;
						if (userData === undefined) {
							setAuthErrorMessage(`No user doc found in Firestore at: ${docRef.path}`);
						} else {
							const profileData: Profile = {
								...userData,
								isPremium: false,
								idToken: await user.getIdToken(true),
							};
							interval = setInterval(async () => {
								if (user) {
									const token = await user.getIdToken(true);
									console.log("Token refreshed");
									setProfile((prev) => ({ ...prev, idToken: token }));
								}
							}, 1000 * 60 * 30);
							setProfile(profileData as Profile);
						}
					});
					const questsRef = collection(myFS, `users/${uid}/dailyQuests`);
					unsubscribeDailyQuests = onSnapshot(questsRef, async (querySnap) => {
						if (!querySnap.empty) {
							let quests = [];
							for (let i = 0; i < querySnap.docs.length; i++) {
								const dailyQuest = querySnap.docs[i].data() as DailyUserQuestDBData;
								if (dailyQuest.questRef != undefined) {
									const questRef = await getDoc<DailyQuest>(dailyQuest.questRef);
									const quest: DailyUserQuest = {
										description: questRef.data().description,
										progression: dailyQuest.progression,
										game: dailyQuest.game,
										level: questRef.data().level,
										statsForQuest: questRef.data().statsForQuest,
										goal: questRef.data().goal,
										descriptions: questRef.data().descriptions,
										dp: questRef.data().dailyPoints,
									};
									quests.push(quest);
								}
							}
							setDailyQuests(quests);
						}
					});
					const monthlyQuestsRef = collection(myFS, `users/${uid}/monthlyQuests`);
					unsubscribeMonthlyQuests = onSnapshot(monthlyQuestsRef, async (querySnap) => {
						if (!querySnap.empty) {
							let quests = [];
							for (let i = 0; i < querySnap.docs.length; i++) {
								const monthlyQuest = querySnap.docs[i].data() as MonthlyUserQuestDBData;
								//monthlyQuest.game.includes(profile.favouriteGame
								if (true) {
									if (monthlyQuest.questRef != undefined) {
										const questRef = await getDoc<MonthlyQuest>(monthlyQuest.questRef);
										const quest: MonthlyUserQuest = {
											descriptions: questRef.data().descriptions,
											description: questRef.data().description,
											progression: monthlyQuest.progression,
											game: monthlyQuest.game,
											level: questRef.data().level,
											statsForQuest: questRef.data().statsForQuest,
											rewardBolts: questRef.data().rewardBolts,
											goal: questRef.data().goal,
										};
										quests.push(quest);
									}
								}
							}
							setMonthlyQuests(quests);
						}
					});
				} catch (ex) {
					console.error(`useEffect() failed with: ${ex.message}`);
					setAuthErrorMessage(ex.message);
				}
			};

			if (user?.uid) {
				listenToUserDoc(user.uid);
				return () => {
					clearInterval(interval);
					unsubscribe?.();
					unsubscribe_?.();
					unsubscribeDailyQuests?.();
					unsubscribeMonthlyQuests?.();
				};
			} else if (!user) {
				setAuthLoading(true);
				setProfile(null);
				setAuthErrorMessage(null);
			}
		}
	}, [user, setProfile, myFS]);

	const AuthProviderData: AuthProviderData = {
		authErrorMessage,
		authLoading,
		profile,
		dailyQuests,
		monthlyQuests,
		notifications: notifications,
		setNotifications: setNotifications,
		user,
		setUser,
		lastNotification: lastNotificationReceveid,
		logout: logoutFunction,
		register: registerFunction,
		validateCode: validateCode,
		checkIfUsernameIsTaken: checkIfUsernameIsTaken,
		connectSteam: connectSteam,
		login: loginFunction,
		changeImage: changeImage,
		createUserAfterGoogleSignIn: createUserAfterGoogleSignIn,
		sendVerification: sendVerification,
		connectEpicGames: connectEpicGames,
		loading,
	};

	return <AuthContext.Provider value={AuthProviderData}>{children}</AuthContext.Provider>;
};
