import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { skipTutorialAPI } from "../../utilities/network/api";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faForward } from "@fortawesome/free-solid-svg-icons";

import { Box } from "@mui/material";

import { useContext } from "react";
import { AuthContext } from "../Providers/AuthProvider";
import { useTranslation } from "react-i18next";
import { NetworkContext } from "../Providers/NetworkProvider";

import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import styled, { css, keyframes } from "styled-components";
import { useState } from "react";
import "../../styles/Tutorial.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { PulseDivProps } from "../../types/Props";
import { SkipTutorialAPIResponse } from "../../types/APIResponse";

export const TutorialOverlayStyle = styled.div`
	width: 100vw;
	height: 100%;
	background-color: #000000;
	opacity: 0.8;
	position: fixed;
	top: 0;
	z-index: 100;
`;

const pulseAnimation = (color: string) => keyframes`
	0% {
        box-shadow: 0px 0px 20px -10px ${color};
    }

    100% {
        box-shadow: 0px 0px 20px 5px ${color};
    }
`;

export const PulseDiv = styled.div`
	${(props: PulseDivProps) =>
		props.pulse &&
		css`
			transition: transform 1s;
			animation: ${(props) => pulseAnimation(props.color)} 0.7s infinite alternate !important;
			padding-right: 0.4rem;
			border-radius: 30px;
		`}
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const TutorialOverlay = (): JSX.Element => {
	const { profile } = useContext(AuthContext);
	const { makePost } = useContext(NetworkContext);
	const { t } = useTranslation("dashboard");

	const [welcomeDialog, setWelcomeDialog] = useState(true);

	const skipTutorial = async (): Promise<void> => {
		const body = { _token: `Bearer ${profile.idToken}` };
		await makePost<SkipTutorialAPIResponse>(skipTutorialAPI, body);
		window.location.href = "/dashboard";
	};

	const TutorialSkip = (): JSX.Element => {
		return (
			<Box
				width={{ xs: "50vw", sm: "30vw", md: "20vw", lg: "15vw" }}
				bottom={{ xs: "5em", md: "0" }}
				borderRadius={{ xs: "0 15px 15px 0", md: "border-radius: 0 15px 0 0" }}
				className="tutorial-skip text-p"
				onClick={skipTutorial}>
				{t("skip tutorial")} <FontAwesomeIcon icon={faForward as IconProp} style={{ paddingLeft: "5px" }} />
			</Box>
		);
	};

	const TutorialWelcomeDialog = (): JSX.Element => {
		return (
			<Dialog className="dialog-confirmation" open={welcomeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title" className="text-center">
					<p className="text-h3">{t("welcome to storm")}</p>
				</DialogTitle>
				<hr />
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<div className="text-p">
							<p>{t("storm welcomes you")}</p>
							<br></br>
							<p>{t("follow quick tutorial")}</p>
							<br></br>
							<p>
								{t("first of all")}
								<br></br> {t("visit the")} <span className="text-p-bold">{t("gamehub section")}</span>.
							</p>
							<br></br>
						</div>

						<a className="tutorial-next-step text-p btn-fill" onClick={() => setWelcomeDialog(false)}>
							{t("next step")} <FontAwesomeIcon icon={faArrowRight as IconProp} />
						</a>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		);
	};

	return (
		<>
			<TutorialWelcomeDialog />
			<TutorialSkip />
			<TutorialOverlayStyle />
		</>
	);
};
