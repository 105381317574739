import { useContext, useState } from "react";
import { AuthContext } from "../../../../Providers/AuthProvider";
import { Backdrop, Button, CircularProgress, Grid, Input, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useSnackbar } from "../../../../Providers/SnackbarProvider";
import { NetworkContext } from "../../../../Providers/NetworkProvider";
import { InventoryAPI } from "../../../../../utilities/network/api";
import { faChevronDown, faChevronUp, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Requests } from "../../../../../types/Requests";
import { Inventory } from "../../../../../types/Inventory";
import { EditDialog } from ".";
import { MARKET_ITEMS_TABS } from "..";
import { FirebaseContext } from "components/Providers/FirebaseProvider";
import { Image } from "react-bootstrap";
import { doc, updateDoc } from "firebase/firestore";

export const EditRewardsPanel = ({
	value,
	rewards,
	onDeleteRewardItem,
	refetchNewItems,
}: {
	value: number;
	rewards: Inventory.Reward[];
	onDeleteRewardItem: (index: number) => void;
	refetchNewItems: () => Promise<void>;
}): JSX.Element => {
	const { myStorageUrl, myFS } = useContext(FirebaseContext);
	const { profile } = useContext(AuthContext);
	const { makePost } = useContext(NetworkContext);
	const { openSnackbar } = useSnackbar();

	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [editRewardIndex, setEditRewardIndex] = useState<number>(-1);
	const [editReward, setEditReward] = useState<Requests.Inventory.Reward | null>(null);

	const [isLoading, setIsLoading] = useState(false);

	async function removeRewardItem(rewardItem: Requests.Inventory.Reward, index: number): Promise<void> {
		try {
			const response = await makePost(`${InventoryAPI.Admin.removeRewardItem}`, {
				rewardItem: rewardItem,
				_token: `Bearer ${profile.idToken}`,
			});

			if (response != null && response.status === 200) {
				openSnackbar("Success", response.data.message, "success");
				onDeleteRewardItem(index);
			} else {
				if (response.data.message != null) {
					openSnackbar("Error", response.data.message, "error");
				} else {
					openSnackbar("Error", "Something went wrong", "error");
				}
			}
		} catch (error) {
			console.log(error);
			if (error) {
				openSnackbar("Error", error.message, "error");
			}
		}
	}

	async function handleMoveRewardItem({
		index,
		direction,
		newIndex,
	}: {
		index: number;
		direction?: "up" | "down";
		newIndex?: number;
	}): Promise<void> {
		try {
			setIsLoading(true);
			const newRewards = [...rewards];

			if (direction != null) {
				if (direction === "up") {
					[newRewards[index], newRewards[index - 1]] = [newRewards[index - 1], newRewards[index]];
				} else {
					[newRewards[index], newRewards[index + 1]] = [newRewards[index + 1], newRewards[index]];
				}
			}

			if (newIndex != null) {
				if (newIndex < 0 && newIndex > newRewards.length - 1) {
					openSnackbar("Error", "Invalid index", "error");
					return;
				}

				[newRewards[index], newRewards[newIndex]] = [newRewards[newIndex], newRewards[index]];
			}

			await updateDoc(doc(myFS, `inventory/items`), {
				rewards: newRewards,
			});

			if (direction) openSnackbar("Success", `Reward moved ${direction} successfully`, "success");
			else openSnackbar("Success", `Reward moved from position ${index + 1} to position ${newIndex + 1} successfully`, "success");

			await refetchNewItems();

			setIsLoading(false);
		} catch (error) {
			console.log(error);
			if (error) {
				openSnackbar("Error", error.message, "error");
			}
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<>
			<Backdrop sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })} open={isLoading}>
				<CircularProgress color="inherit" size={50} />
			</Backdrop>
			<Grid container justifyContent="center" role="tabpanel" hidden={value !== MARKET_ITEMS_TABS.REWARDS} gap={3} maxHeight="80vh" overflow="scroll">
				<EditDialog
					open={openEditDialog}
					onClose={() => setOpenEditDialog(false)}
					reward={editReward}
					index={editRewardIndex}
					refetchNewItems={refetchNewItems}
				/>
				<Table
					stickyHeader
					sx={{
						".MuiTableCell-root": {
							color: "white",
							p: 1,
						},
						".MuiTableHead-root .MuiTableCell-root": {
							color: "black",
							textAlign: "center",
						},
					}}>
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell sx={{ borderRight: "3px solid red" }}>
								<Typography variant="h5">Service</Typography>
							</TableCell>
							<TableCell sx={{ borderRight: "3px solid red" }}>
								<Typography variant="h5">Reward</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="h5"># items</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rewards.length > 0 &&
							rewards.map((reward, indexR) => (
								<TableRow
									key={`${reward.service}-${reward.reward}-${indexR}`}
									sx={{
										".MuiTableCell-root": {
											p: 0,
										},
									}}>
									<TableCell align="center">
										<Stack
											direction="column"
											alignItems="center"
											gap={1}
											sx={{
												cursor: "pointer",
											}}>
											{indexR !== 0 && (
												<FontAwesomeIcon
													icon={faChevronUp as IconProp}
													className="fa-2x"
													onClick={async () =>
														await handleMoveRewardItem({
															index: indexR,
															direction: "up",
														})
													}
												/>
											)}
											<Input
												type="number"
												defaultValue={indexR + 1}
												sx={{
													width: "50px",
													color: "black",
													backgroundColor: "white",
													pl: 2,
												}}
												onBlur={async (e) => {
													const newIndex = parseInt(e.target.value) - 1;
													if (newIndex >= 0 && newIndex <= rewards.length - 1) {
														await handleMoveRewardItem({
															index: indexR,
															newIndex: newIndex,
														});
													} else {
														openSnackbar("Error", "Invalid index", "error");
														e.target.value = `${indexR + 1}`;
													}
												}}
											/>
											{indexR !== rewards.length - 1 && (
												<FontAwesomeIcon
													icon={faChevronDown as IconProp}
													className="fa-2x"
													onClick={async () =>
														await handleMoveRewardItem({
															index: indexR,
															direction: "down",
														})
													}
												/>
											)}
										</Stack>
									</TableCell>
									<TableCell align="center">
										<Image src={`${myStorageUrl}images/market/${reward.imageName}`} alt="" width="100px" />
									</TableCell>
									<TableCell>
										<Typography variant="h5" textAlign="center" pl={5} fontWeight="bold">
											{reward.service}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="h6" pl={5}>
											{reward.reward}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography
											variant="body1"
											sx={{
												bgcolor: "primary.main",
												textAlign: "center",
												px: 5,
											}}>
											{reward.items.length}
										</Typography>
									</TableCell>
									<TableCell>
										<Button
											type="button"
											sx={{
												display: "flex",
												alignItems: "center",
												gap: 1,
												backgroundColor: "success.main",
												color: "white",
												border: 0,
												borderRadius: 2,
												boxShadow: 1,
												cursor: "pointer",
												fontSize: 16,
												fontWeight: "bold",
												lineHeight: 1.5,
												padding: "10px 20px",
												margin: "10px",
												"&:hover": {
													backgroundColor: "success.dark",
												},
											}}
											onClick={() => {
												setEditReward(reward);
												setEditRewardIndex(indexR);
												setOpenEditDialog(true);
											}}>
											<FontAwesomeIcon icon={faEdit as IconProp} className="fa-xl" /> EDIT
										</Button>
									</TableCell>
									<TableCell>
										<Button
											type="button"
											sx={{
												display: "flex",
												alignItems: "center",
												gap: 1,
												backgroundColor: "error.main",
												color: "white",
												border: 0,
												borderRadius: 2,
												boxShadow: 1,
												cursor: "pointer",
												fontSize: 16,
												fontWeight: "bold",
												lineHeight: 1.5,
												padding: "10px 20px",
												margin: "10px",
												"&:hover": {
													backgroundColor: "error.dark",
												},
											}}
											onClick={() => removeRewardItem(rewards[indexR], indexR)}>
											<FontAwesomeIcon icon={faTrash as IconProp} className="fa-xl" /> DELETE
										</Button>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</Grid>
		</>
	);
};
