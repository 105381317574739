import { useContext, useState } from "react";
import { FirebaseContext } from "../../../../Providers/FirebaseProvider";
import { AuthContext } from "../../../../Providers/AuthProvider";
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { Field, FieldArray, Form, Formik, FormikHelpers } from "formik";
import { useSnackbar } from "../../../../Providers/SnackbarProvider";
import { NetworkContext } from "../../../../Providers/NetworkProvider";
import { InventoryAPI } from "../../../../../utilities/network/api";
import { CustomTextArea } from "../../CustomTextArea";
import { Image } from "react-bootstrap";
import { faCloudArrowUp, faPlus, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Requests } from "../../../../../types/Requests";
import { itemInitialValue } from ".";

export const EditDialog = ({
	open,
	onClose,
	reward,
	index,
	refetchNewItems,
}: {
	open: boolean;
	onClose: () => void;
	reward: Requests.Inventory.Reward;
	index: number;
	refetchNewItems: () => Promise<void>;
}): JSX.Element => {
	const { myStorageUrl } = useContext(FirebaseContext);
	const { profile } = useContext(AuthContext);
	const { makePost } = useContext(NetworkContext);
	const { openSnackbar } = useSnackbar();

	const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null>(null);
	const [imageItemsPreview, setImageItemsPreview] = useState<(string | ArrayBuffer)[] | null>(null);

	async function editRewardItem(rewardItem: Requests.Inventory.Reward, index: number): Promise<void> {
		try {
			if (rewardItem == null) delete rewardItem.image;

			const response = await makePost(`${InventoryAPI.Admin.editRewardItem}`, {
				rewardItem: rewardItem,
				index: index,
				_token: `Bearer ${profile.idToken}`,
			});

			if (response != null && response.status === 200) {
				openSnackbar("Success", response.data.message, "success");
				await refetchNewItems();
				onClose();
			} else {
				if (response.data.message != null) {
					openSnackbar("Error", response.data.message, "error");
				} else {
					openSnackbar("Error", "Something went wrong", "error");
				}
			}
		} catch (error) {
			console.log(error);
			if (error) {
				openSnackbar("Error", error.message, "error");
			}
		}
	}

	async function handleSubmit(values: Requests.Inventory.Reward, formikHelpers: FormikHelpers<Requests.Inventory.Reward>): Promise<void> {
		console.log(values);

		// check if every field is not empty
		if (
			values.service === "" ||
			values.reward === "" ||
			values.description.en === "" ||
			values.description.it === "" ||
			values.image === "" ||
			values.imageName === ""
		) {
			openSnackbar("Error", "Please fill all the fields", "error");
			return;
		}

		// check if every field of the items is not empty
		for (let i = 0; i < values.items.length; i++) {
			if (values.items[i].name === "" || values.items[i].price === 0 || values.items[i].priceInBolts === 0) {
				openSnackbar("Error", "Please fill all the fields", "error");
				return;
			}

			if (values.items[i].image && values.items[i].image.length === 0) {
				delete values.items[i].image;
			}
			if (values.items[i].imageName && values.items[i].imageName.length === 0) {
				delete values.items[i].imageName;
			}

			values.items[i].uid = i;
		}

		const rewardItem: Requests.Inventory.Reward = {
			service: values.service.trim(),
			reward: values.reward.trim(),
			description: values.description,
			image: values.image,
			imageName: values.imageName,
			items: values.items,
		};
		await editRewardItem(rewardItem, index);
	}

	return (
		<Box
			component={Dialog}
			sx={{
				"& .MuiPaper-root": {
					minHeight: "400px",
					minWidth: "80vw",
				},
			}}
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle>Edit Reward</DialogTitle>
			<DialogContent>
				<Formik enableReinitialize initialValues={reward} onSubmit={handleSubmit}>
					{({ values, setFieldValue }) => (
						<Form style={{ width: "100%" }}>
							<Grid container gap={1} flexDirection="column" alignItems="center">
								<Grid item display="flex" flexDirection="column" gap={1}>
									<Grid item display="flex" justifyContent="center" gap={1}>
										<Box component={Field} name="service" placeholder="Service Name" type="text" sx={{ width: "20%" }} required />
										<Box component={Field} name="reward" placeholder="Item Name" type="text" sx={{ width: "20%" }} required />
									</Grid>
									<Grid item display="flex" flexDirection={{ xs: "column", md: "row" }} justifyContent="space-between" gap={1}>
										<CustomTextArea
											name="description.en"
											placeholder="ENG Description"
											initialValue={reward.description.en}
											wrapperStyle={{
												width: "50%",
											}}
										/>
										<CustomTextArea
											name="description.it"
											placeholder="ITA Description"
											initialValue={reward.description.it}
											wrapperStyle={{
												width: "50%",
											}}
										/>
									</Grid>
									<Box display="flex" textAlign="center" justifyContent="center" alignItems="center" gap={5}>
										{!imagePreview && reward.imageName ? <Image width="300px" src={`${myStorageUrl}images/market/${reward.imageName}`} /> : null}
										{imagePreview ? <Image width="300px" src={imagePreview as string} /> : null}

										<Button variant="contained" component="label">
											<Box display="flex" textAlign="center" justifyContent="center" alignItems="center" gap={1}>
												<FontAwesomeIcon icon={faCloudArrowUp as IconProp} className="fa-xl" />
												{reward.imageName ? "Change" : "Upload"} Default Image
												<input
													name="image"
													accept="image/*"
													id="contained-button-file"
													type="file"
													hidden
													onChange={(e) => {
														const fileReader = new FileReader();
														fileReader.onload = () => {
															console.log(fileReader.result);
															if (fileReader.readyState === 2) {
																setFieldValue("image", fileReader.result as string);
																setFieldValue("imageName", e.target.files[0].name);
																setImagePreview(fileReader.result);
															}
														};
														fileReader.readAsDataURL(e.target.files[0]);
													}}
												/>
											</Box>
										</Button>
									</Box>
								</Grid>

								<Grid container justifyContent="center" alignItems="center" flexDirection="column" mt={5} mb={5}>
									<FieldArray name="items">
										{({ insert, remove, push }) =>
											values.items.length > 0 &&
											values.items.map((item, index) => (
												<Grid item key={index} display="flex" alignItems="end" gap={1} mb={1}>
													<Grid item display="flex" flexDirection="column" gap={1}>
														<label>
															Reward Name <span style={{ color: "red" }}>*</span>
														</label>
														<Field name={`items.${index}.name`} placeholder="Reward Name" type="text" required />
													</Grid>
													<Grid item display="flex" flexDirection="column" gap={1}>
														<label>
															Price <span style={{ color: "red" }}>*</span>
														</label>
														<Field name={`items.${index}.price`} placeholder="Price" type="number" required />
													</Grid>
													<Grid item display="flex" flexDirection="column" gap={1}>
														<label>
															Price in Bolts <span style={{ color: "red" }}>*</span>
														</label>
														<Field name={`items.${index}.priceInBolts`} placeholder="Price in Bolts" type="number" required />
													</Grid>
													<Box display="flex" textAlign="center" justifyContent="center" alignItems="center" gap={5}>
														{!(imageItemsPreview && imageItemsPreview[index]) && item.imageName ? (
															<Image width="100px" src={`${myStorageUrl}images/market/${item.imageName}`} />
														) : null}
														{imageItemsPreview && imageItemsPreview[index] ? <Image width="100px" src={imageItemsPreview[index] as string} /> : null}

														<Button variant="contained" component="label">
															<Box display="flex" textAlign="center" justifyContent="center" alignItems="center" gap={1}>
																<FontAwesomeIcon icon={faCloudArrowUp as IconProp} className="fa-xl" />
																{item.imageName ? "Change" : "Upload"} Image
																<input
																	name="image"
																	accept="image/*"
																	id={`contained-button-file-item-${index}`}
																	type="file"
																	hidden
																	onChange={(e) => {
																		console.log(e.target.files[0]);
																		const fileReader = new FileReader();
																		fileReader.onload = () => {
																			console.log(fileReader.result);
																			if (fileReader.readyState === 2) {
																				setFieldValue(`items.${index}.image`, fileReader.result as string);
																				setFieldValue(`items.${index}.imageName`, e.target.files[0].name);

																				setImageItemsPreview((prev) => {
																					const newPreview = [...(prev || [])];
																					newPreview[index] = fileReader.result;
																					return newPreview;
																				});
																			}
																		};
																		fileReader.readAsDataURL(e.target.files[0]);
																	}}
																/>
															</Box>
														</Button>
													</Box>
													{index > 0 && (
														<Button
															variant="contained"
															color="error"
															type="button"
															onClick={() => {
																remove(index);
															}}>
															<Box display="flex" textAlign="center" justifyContent="center" alignItems="center" gap={1}>
																<FontAwesomeIcon icon={faTimes as IconProp} className="fa-xl" />
																Delete
															</Box>
														</Button>
													)}
													{index === values.items.length - 1 && (
														<Button variant="contained" color="secondary" type="button" onClick={() => push(itemInitialValue)}>
															<Box display="flex" textAlign="center" justifyContent="center" alignItems="center" gap={1}>
																<FontAwesomeIcon icon={faPlus as IconProp} className="fa-xl" />
																Add a new item
															</Box>
														</Button>
													)}
												</Grid>
											))
										}
									</FieldArray>
								</Grid>

								<Button variant="contained" color="success" type="submit" sx={{ width: "20%" }}>
									<Box display="flex" textAlign="center" justifyContent="center" alignItems="center" gap={1}>
										<FontAwesomeIcon icon={faSave as IconProp} className="fa-xl" />
										SAVE
									</Box>
								</Button>
							</Grid>
						</Form>
					)}
				</Formik>
			</DialogContent>
		</Box>
	);
};
