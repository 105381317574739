import React, { useContext, useEffect, useState, useRef, KeyboardEvent } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { AuthContext } from "./Providers/AuthProvider";
import { FirebaseContext } from "./Providers/FirebaseProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import { faClose, faTrash } from "@fortawesome/free-solid-svg-icons";
import { deleteDoc, doc } from "firebase/firestore";
import Badge from "@mui/material/Badge";
import Fade from "@mui/material/Fade";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const Notifications = (): JSX.Element => {
	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);
	const { profile, notifications } = useContext(AuthContext);
	const { t } = useTranslation("dashboard");
	const { myFS } = useContext(FirebaseContext);
	const prevOpen = useRef(open);

	const handleToggle = (): void => {
		if (prevOpen.current) {
			$(".notification-half-filler").addClass("transparent-background");
			$(".notification-header").addClass("transparent-background");
			$(".notification-container").addClass("transparent-background");
		} else {
			$(".notification-header").removeClass("transparent-background");
			$(".notification-half-filler").removeClass("transparent-background");
			$(".notification-container").removeClass("transparent-background");
		}
		setOpen((prevOpen): boolean => !prevOpen);
	};

	const handleClose = (event: MouseEvent | TouchEvent): void => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		$(".notification-half-filler").addClass("transparent-background");
		$(".notification-header").addClass("transparent-background");
		$(".notification-container").addClass("transparent-background");
		setOpen(false);
	};

	function handleListKeyDown(event: KeyboardEvent<HTMLUListElement>): void {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === "Escape") {
			setOpen(false);
		}
	}

	const deleteNotification = async (id: string): Promise<void> => {
		const docRef = doc(myFS, `users/${profile.uid}/notifications/${id}`);
		await deleteDoc(docRef);
	};

	const deleteNotificationBox = async (notificationId: string): Promise<void> => {
		$(`#${notificationId}`).fadeTo(500, 0, () => {
			$(`#${notificationId}`).css("display", "none");
		});
		await deleteNotification(notificationId);
	};

	const deleteAllNotificationBox = async (): Promise<void> => {
		if (notifications.length === 0) return;

		for (let i = 0; i < notifications.length; ++i) {
			await deleteNotification(notifications[i].id);
			$(`#${notifications[i].id}`)
				.delay(i * 500)
				.fadeTo(500, 0, () => {
					//When fadeTo is complete -> set css display: none
					$(`#${notifications[i].id}`).css("display", "none");
				});
		}
	};

	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	const theme = createTheme({
		palette: {
			primary: {
				main: "#ffffff",
				contrastText: "#fff",
			},
		},
	});

	return (
		<>
			{/* {lastNotificationShow} */}
			<Stack direction="row" spacing={2} className="notification-desktop">
				<div className="notification-container transparent-background">
					<ThemeProvider theme={theme}>
						<Badge color="primary" variant="dot" invisible={notifications.length === 0} overlap="circular">
							<NotificationsIcon
								fontSize="large"
								ref={anchorRef}
								id="notifications-icon"
								aria-controls={open ? "composition-menu" : undefined}
								aria-expanded={open ? "true" : undefined}
								aria-haspopup="true"
								onClick={handleToggle}
								className={`notifications-icon ${notifications.length > 0 ? "purple-color" : ""}`}></NotificationsIcon>
						</Badge>
					</ThemeProvider>
					<Popper
						open={open}
						anchorEl={anchorRef.current}
						role={undefined}
						placement="bottom-end"
						transition
						disablePortal
						className="notification-dropdown">
						{({ TransitionProps, placement }): JSX.Element => (
							<Fade
								{...TransitionProps}
								style={{
									transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
								}}
								timeout={0}>
								<Paper>
									<ClickAwayListener onClickAway={handleClose}>
										<MenuList
											autoFocusItem={open}
											id="composition-menu"
											aria-labelledby="notifications-icon"
											onKeyDown={handleListKeyDown}
											sx={{
												maxHeight: "50vh",
												overflowY: "scroll",
												p: 0,
												zIndex: 9999,
											}}>
											<Grid>
												<div>
													<Stack
														position="sticky"
														top={0}
														zIndex={10}
														direction="row"
														justifyContent="space-between"
														alignItems="center"
														borderRadius="20px 0 0 0 "
														py={2}
														px={4}
														minWidth={700}
														sx={{
															background: "black",
														}}>
														<Typography variant="h5" fontWeight={500} fontFamily="Sora">
															{t("desktop.notification.title")}
														</Typography>
														{notifications.length > 0 && (
															<Button onClick={deleteAllNotificationBox} variant="outlined" color="error">
																<Typography
																	variant="body1"
																	fontWeight={400}
																	fontFamily="Sora"
																	sx={{
																		display: "flex",
																		alignItems: "center",
																		gap: 2,
																	}}>
																	{t("desktop.notification.delete_all")}
																	<Box component={FontAwesomeIcon} icon={faTrash as IconProp} sx={{ mb: 0.5 }} />
																</Typography>
															</Button>
														)}
													</Stack>
													<Divider className="border-line" sx={{ mb: 2, px: 2 }} />

													{notifications.length > 0 &&
														notifications.map(({ id, message, defaultMessage, time }, i) => (
															<React.Fragment key={id}>
																<NotificationItem
																	id={id}
																	message={message}
																	defaultMessage={defaultMessage}
																	time={time}
																	deleteAction={async () => await deleteNotificationBox(id)}
																/>
																{i !== notifications.length - 1 && <Divider className="border-line" sx={{ my: 2, px: 2 }} />}
															</React.Fragment>
														))}

													{notifications.length === 0 && (
														<Typography variant="h6" fontFamily="Sora" textAlign="center" py={3}>
															{t("desktop.notification.no_notifications")}
														</Typography>
													)}
												</div>
											</Grid>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Fade>
						)}
					</Popper>
				</div>
			</Stack>

			<Grid className="notifications-mobile">
				<div className="box">
					<Grid container>
						<Grid item xs={11}>
							<h1 className="notification-title">{t("notifications")}</h1>
						</Grid>
						<Grid item xs={1}>
							<p className="text-label-underlined-opicity delete-all-text" onClick={deleteAllNotificationBox}>
								Delete all
							</p>
						</Grid>
					</Grid>
					<hr className="border-line" />

					{notifications.map(({ id, message, defaultMessage, time }, i) => (
						<React.Fragment key={id}>
							<NotificationItem
								id={id}
								message={message}
								defaultMessage={defaultMessage}
								time={time}
								deleteAction={async () => await deleteNotification(id)}
							/>
							{i !== notifications.length - 1 && <Divider sx={{ my: 4 }} />}
						</React.Fragment>
					))}
				</div>
			</Grid>
		</>
	);
};

type NotificationItemProps = {
	message: string;
	defaultMessage: string;
	time: { date: number; hours: number; minutes: number };
	id: string;
	deleteAction: () => void;
};

const NotificationItem = ({ message, defaultMessage, time, id, deleteAction }: NotificationItemProps) => {
	const { t } = useTranslation("dashboard");
	const timePassed = `${time.date !== 0 ? `${time.date}d ` : ""}${time.hours !== 0 ? `${time.hours}h ` : ""}${
		time.minutes !== 0 ? `${time.minutes}m ` : ""
	}`;

	return (
		<Grid container direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"} className="notification-box-content" id={id}>
			<Grid item md={1} alignSelf={"center"}>
				<img src={process.env.PUBLIC_URL + "/eStorm_logo.svg"} className="notification2" alt="notification2" />
			</Grid>
			<Grid item xs md className="notification-content-text">
				<Grid container direction={"column"}>
					<Grid item>
						<Box component="span" className="text-p-bold" sx={{ whiteSpace: "break-spaces" }}>
							{t(message, {
								defaultValue: defaultMessage,
							})}
						</Box>
					</Grid>
					<Grid item>
						<span className="text-label">
							{timePassed.length !== 0 ? timePassed + ` ${t("desktop.notification.ago")}` : t("desktop.notification.now")}
						</span>
					</Grid>
				</Grid>
			</Grid>
			<Box component={Grid} item md={1} alignSelf={"baseline"} display={{ xs: "none", md: "block", lg: "block" }} className="close-notification">
				<FontAwesomeIcon icon={faClose as IconProp} className="crossNotifications fa-2xl" onClick={deleteAction} />
			</Box>
		</Grid>
	);
};
