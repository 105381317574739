import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperType } from "swiper";
import SilverBoxImage from "assets/images/silver_box.svg";
import VoidBoxImage from "assets/images/void_box.svg";
import GoldBoxImage from "assets/images/gold_box.svg";
import GalaxyBoxImage from "assets/images/galaxy_box.svg";
import { Inventory } from "types/Inventory";
import { MarketBoxImage } from "../MarketBoxImage";

const BOXES = { silverBox: SilverBoxImage, voidBox: VoidBoxImage, goldBox: GoldBoxImage, galaxyBox: GalaxyBoxImage } as const;

type SwiperBoxProps = {
	setSelected: React.Dispatch<React.SetStateAction<Inventory.GemsPacksName>>;
	swiper: SwiperType;
	setSwiper: React.Dispatch<React.SetStateAction<SwiperType>>;
};

export const SwiperBox = ({ setSelected, swiper, setSwiper }: SwiperBoxProps) => {
	return (
		<Swiper
			key={`swiper-gems-pack`}
			spaceBetween={30}
			id={`swiper-gems-pack`}
			slidesPerView={1}
			onInit={setSwiper}
			initialSlide={0}
			onSlideChange={() => {
				setSelected(Object.keys(BOXES)[swiper.activeIndex] as Inventory.GemsPacksName);
			}}>
			{Object.entries(BOXES).map(([key, imageSrc]) => (
				<SwiperSlide
					key={"slide-" + key}
					className="next "
					style={{
						display: "flex",
						justifyContent: "center",
					}}>
					<MarketBoxImage src={imageSrc} />
				</SwiperSlide>
			))}
		</Swiper>
	);
};
