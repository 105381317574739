import { Dialog, DialogContent, DialogTitle, Grid, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { Dispatch, SetStateAction, useEffect } from "react";
import { Inventory } from "types/Inventory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { RewardChoice, PurchaseRecap, DescriptionDetails } from ".";
import { useMarketReward } from "components/Providers/MarketRewardProvider";

type MarketRewardDialogProps = {
	reward: Inventory.Reward;
	openRewardDialog: boolean;
	setOpenRewardDialog: Dispatch<SetStateAction<boolean>>;
};

export const MarketRewardDialog = ({ reward, openRewardDialog, setOpenRewardDialog }: MarketRewardDialogProps) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
	const { itemIndex, setItemIndex, setDiscountInBoltsUsed, setNewPriceAfterDiscount } = useMarketReward();

	const handleClose = () => {
		setItemIndex(0);
		setOpenRewardDialog(false);
	};

	useEffect(() => {
		if (openRewardDialog) {
			setDiscountInBoltsUsed(0);
			setNewPriceAfterDiscount(reward.items[itemIndex].price);
		}
	}, [reward.items, itemIndex, openRewardDialog, setDiscountInBoltsUsed, setNewPriceAfterDiscount]);

	return (
		<Dialog
			open={openRewardDialog}
			onClose={handleClose}
			fullScreen={fullScreen}
			maxWidth="xxxl"
			sx={{
				"& .MuiDialog-paper": {
					width: "100%",
				},
				"& *": {
					fontFamily: "Sora",
				},
			}}>
			<DialogTitle
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					alignItems: "center",
				}}>
				<IconButton
					onClick={handleClose}
					sx={{
						color: "rgba(255, 255, 255, 0.6)",
						transition: "all 0.3s ease-in-out",
						":hover": {
							rotate: "90deg",
							scale: 1.2,
							color: "var(--error)",
						},
					}}>
					<FontAwesomeIcon icon={faTimes as IconProp} className="fa-xl" />
				</IconButton>
			</DialogTitle>
			<DialogContent
				sx={{
					width: "100%",
					pb: 5,
				}}>
				<Grid container alignItems="center" justifyContent="space-around" flexDirection={{ xs: "column", md: "row" }}>
					<RewardChoice reward={reward} />

					<DescriptionDetails key={`description-details-${reward.items[itemIndex].name}-${reward.items[itemIndex].uid}`} reward={reward} />

					<PurchaseRecap
						key={`purchase-recap-${reward.items[itemIndex].name}-${reward.items[itemIndex].uid}`}
						reward={reward}
						onClose={handleClose}
					/>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};
