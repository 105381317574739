import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCircleQuestion, faCloudArrowUp, faPaperPlane, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	Stack,
	styled,
	TooltipProps,
	tooltipClasses,
	useMediaQuery,
	Tooltip,
	Typography,
} from "@mui/material";
import { Field, FieldArray, Form, Formik, FormikHelpers } from "formik";
import { useContext, useState } from "react";
import { Image } from "react-bootstrap";
import { initialValues, itemInitialValue } from ".";
import { MARKET_TABS } from "..";
import { Requests } from "../../../../../types/Requests";
import { InventoryAPI } from "../../../../../utilities/network/api";
import { AuthContext } from "../../../../Providers/AuthProvider";
import { NetworkContext } from "../../../../Providers/NetworkProvider";
import { useSnackbar } from "../../../../Providers/SnackbarProvider";
import { CustomTextArea } from "../../CustomTextArea";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps): JSX.Element => <Tooltip {...props} classes={{ popper: className }} />)(
	({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			background: "rgba(0,0,0,1)",
			color: "rgba(255, 255, 255, 0.87)",
			maxWidth: 300,
			minWidth: 250,
			borderRadius: 10,
			border: "1px solid #FFFFFF5A",
		},
	})
);

export const AddRewardPanel = ({ value, refetchNewItems }: { value: number; refetchNewItems: () => Promise<void> }): JSX.Element => {
	const { profile } = useContext(AuthContext);
	const { makePost } = useContext(NetworkContext);
	const { openSnackbar } = useSnackbar();
	const isMobile = useMediaQuery("(max-width: 1099px)");
	const [isLoading, setIsLoading] = useState(false);

	const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null>(null);
	const [imageItemsPreview, setImageItemsPreview] = useState<(string | ArrayBuffer)[] | null>(null);

	async function addRewardItem(rewardItem: Requests.Inventory.Reward | null, formikHelpers: FormikHelpers<typeof initialValues>): Promise<void> {
		try {
			if (rewardItem == null) {
				openSnackbar("Error", "Please specify a reward", "error");
				return;
			}
			setIsLoading(true);

			const response = await makePost(`${InventoryAPI.Admin.addRewardItem}?_token=${profile.idToken}`, {
				rewardItem: rewardItem,
			});

			if (response != null && response.status === 200) {
				openSnackbar("Success", response.data.message, "success");

				// Clearing the form
				formikHelpers.resetForm();

				// Clearing the description fields
				formikHelpers.setFieldValue("description.en", initialValues.description.en);
				formikHelpers.setFieldValue("description.it", initialValues.description.it);

				// Clearing the image fields
				formikHelpers.setFieldValue("image", initialValues.image);
				formikHelpers.setFieldValue("imageName", initialValues.imageName);
				setImagePreview(null);

				formikHelpers.setFieldValue("items", [itemInitialValue]);
				setImageItemsPreview(null);

				await refetchNewItems();
			} else {
				if (response.data.message != null) {
					openSnackbar("Error", response.data.message, "error");
				} else {
					openSnackbar("Error", "Something went wrong", "error");
				}
			}
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			if (error) {
				openSnackbar("Error", error.message, "error");
			}
		} finally {
			setIsLoading(false);
		}
	}

	async function handleSubmit(values: typeof initialValues, formikHelpers: FormikHelpers<typeof initialValues>): Promise<void> {
		console.log(values);

		// check if every field is not empty
		if (
			values.service === "" ||
			values.reward === "" ||
			values.description.en === "" ||
			values.description.it === "" ||
			values.image === "" ||
			values.imageName === ""
		) {
			openSnackbar("Error", "Please fill all the fields", "error");
			return;
		}

		// check if every field of the items is not empty
		for (let i = 0; i < values.items.length; i++) {
			if (values.items[i].name === "" || values.items[i].price === 0 || values.items[i].priceInBolts === 0) {
				openSnackbar("Error", "Please fill all the fields", "error");
				return;
			}

			if (values.items[i].image && values.items[i].image.length === 0) {
				delete values.items[i].image;
			}
			if (values.items[i].imageName && values.items[i].imageName.length === 0) {
				delete values.items[i].imageName;
			}

			values.items[i].name = values.items[i].name.trim();
			values.items[i].uid = i;
		}

		const rewardItem: Requests.Inventory.Reward = {
			service: values.service.trim(),
			reward: values.reward.trim(),
			description: values.description,
			image: values.image,
			imageName: values.imageName,
			items: values.items,
		};
		await addRewardItem(rewardItem, formikHelpers);
	}

	return (
		<>
			<Backdrop sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })} open={isLoading}>
				<CircularProgress color="inherit" size={50} />
			</Backdrop>
			<Grid item xs={12} display="flex" flexDirection="column" alignItems="center" role="tabpanel" hidden={value !== MARKET_TABS.ADD}>
				<Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
					{({ values, setFieldValue }) => (
						<Form style={{ width: "100%" }}>
							<Grid container gap={1} flexDirection="column" alignItems="center">
								<Grid item display="flex" flexDirection="column" gap={1}>
									<Grid item display="flex" justifyContent="center" gap={1}>
										<Box component={Field} name="service" placeholder="Service Name" type="text" sx={{ width: "20%" }} required />
										<Box component={Field} name="reward" placeholder="Item Name" type="text" sx={{ width: "20%" }} required />
									</Grid>
									<Grid item display="flex" flexDirection={{ xs: "column", md: "row" }} justifyContent="space-between" alignItems="center" gap={1}>
										<CustomTextArea
											name="description.en"
											placeholder="ENG Description"
											wrapperStyle={{
												width: isMobile ? "70%" : "35vw",
												maxWidth: isMobile ? "70%" : "35vw",
											}}
										/>
										<CustomTextArea
											name="description.it"
											placeholder="ITA Description"
											wrapperStyle={{
												width: isMobile ? "70%" : "35vw",
												maxWidth: isMobile ? "70%" : "35vw",
											}}
										/>
									</Grid>
									<Box display="flex" textAlign="center" justifyContent="center" alignItems="center" gap={5}>
										{imagePreview ? <Image width="300px" src={imagePreview as string} /> : "No image"}

										<Button variant="contained" component="label">
											<Box display="flex" textAlign="center" justifyContent="center" alignItems="center" gap={1}>
												<FontAwesomeIcon icon={faCloudArrowUp as IconProp} className="fa-xl" />
												{imagePreview ? "Change" : "Upload"} Default Image
												<input
													name="image"
													accept="image/*"
													id="contained-button-file"
													type="file"
													hidden
													onChange={(e) => {
														const fileReader = new FileReader();
														fileReader.onload = () => {
															console.log(fileReader.result);
															if (fileReader.readyState === 2) {
																setFieldValue("image", fileReader.result as string);
																setFieldValue("imageName", e.target.files[0].name);
																setImagePreview(fileReader.result);
															}
														};
														fileReader.readAsDataURL(e.target.files[0]);
													}}
												/>
											</Box>
										</Button>
									</Box>
								</Grid>

								<Grid container justifyContent="center" alignItems="center" flexDirection="column" mt={5} mb={5}>
									<FieldArray name="items">
										{({ insert, remove, push }) =>
											values.items.length > 0 &&
											values.items.map((item, index) => (
												<Grid item key={index} display="flex" alignItems="end" gap={1} mb={1}>
													<Grid item display="flex" flexDirection="column" gap={1}>
														<label>
															Reward Name <span style={{ color: "red" }}>*</span>
														</label>
														<Field name={`items.${index}.name`} placeholder="Reward Name" type="text" required />
													</Grid>
													<Grid item display="flex" flexDirection="column" gap={1}>
														<label>
															Price (without VAT) <span style={{ color: "red" }}>*</span>
														</label>
														<Stack direction="row" gap={0} alignItems="center">
															<Field name={`items.${index}.price`} placeholder="Price" type="number" required />
															<HtmlTooltip
																hidden={false}
																title={
																	<>
																		<Typography variant="body1">Full price without VAT</Typography>
																		<Typography variant="body1">Write the full price without , or .</Typography>
																		<ul>
																			<li>
																				<Typography variant="body1">eg. price: €5 ==&gt; 500</Typography>
																			</li>
																			<li>
																				<Typography variant="body1">eg. price: €7.50 ==&gt; 750</Typography>
																			</li>
																			<li>
																				<Typography variant="body1">eg. price: €15.20 ==&gt; 1520</Typography>
																			</li>
																			<li>
																				<Typography variant="body1">eg. price: €263.40 ==&gt; 26340</Typography>
																			</li>
																		</ul>
																	</>
																}
																arrow
																placement="top">
																<div>
																	<FontAwesomeIcon icon={faCircleQuestion as IconProp} color="white" />
																</div>
															</HtmlTooltip>
														</Stack>
													</Grid>
													<Grid item display="flex" flexDirection="column" gap={1}>
														<label>
															Price in Bolts <span style={{ color: "red" }}>*</span>
														</label>
														<Field name={`items.${index}.priceInBolts`} placeholder="Price in Bolts" type="number" required />
													</Grid>
													<Box display="flex" textAlign="center" justifyContent="center" alignItems="center" gap={5}>
														{imageItemsPreview && imageItemsPreview[index] ? (
															<Image width="100px" src={imageItemsPreview[index] as string} />
														) : (
															"No image"
														)}

														<Button variant="contained" component="label">
															<Box display="flex" textAlign="center" justifyContent="center" alignItems="center" gap={1}>
																<FontAwesomeIcon icon={faCloudArrowUp as IconProp} className="fa-xl" />
																{imageItemsPreview && imageItemsPreview[index] ? "Change" : "Upload"} Image
																<input
																	name="image"
																	accept="image/*"
																	id={`contained-button-file-item-${index}`}
																	type="file"
																	hidden
																	onChange={(e) => {
																		console.log(e.target.files[0]);
																		const fileReader = new FileReader();
																		fileReader.onload = () => {
																			console.log(fileReader.result);
																			if (fileReader.readyState === 2) {
																				setFieldValue(`items.${index}.image`, fileReader.result as string);
																				setFieldValue(`items.${index}.imageName`, e.target.files[0].name);

																				setImageItemsPreview((prev) => {
																					const newPreview = [...(prev || [])];
																					newPreview[index] = fileReader.result;
																					return newPreview;
																				});
																			}
																		};
																		fileReader.readAsDataURL(e.target.files[0]);
																	}}
																/>
															</Box>
														</Button>
													</Box>
													{index > 0 && (
														<Button
															variant="contained"
															color="error"
															type="button"
															onClick={() => {
																remove(index);
															}}>
															<Box display="flex" textAlign="center" justifyContent="center" alignItems="center" gap={1}>
																<FontAwesomeIcon icon={faTimes as IconProp} className="fa-xl" />
																Delete
															</Box>
														</Button>
													)}
													{index === values.items.length - 1 && (
														<Button variant="contained" color="secondary" type="button" onClick={() => push(itemInitialValue)}>
															<Box display="flex" textAlign="center" justifyContent="center" alignItems="center" gap={1}>
																<FontAwesomeIcon icon={faPlus as IconProp} className="fa-xl" />
																Add a new item
															</Box>
														</Button>
													)}
												</Grid>
											))
										}
									</FieldArray>
								</Grid>

								<Button variant="contained" color="success" type="submit" sx={{ width: "20%" }}>
									<Box display="flex" textAlign="center" justifyContent="center" alignItems="center" gap={1}>
										<FontAwesomeIcon icon={faPaperPlane as IconProp} className="fa-xl" />
										Submit
									</Box>
								</Button>
							</Grid>
						</Form>
					)}
				</Formik>
			</Grid>
		</>
	);
};
