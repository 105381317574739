import { Grid } from "@mui/material";
import { Dispatch, useMemo, useState } from "react";
import { Inventory } from "types/Inventory";
import { SwiperBox } from "./SwiperBox";
import type { Swiper as SwiperType } from "swiper";
import { PriceButton } from "../PriceButton";
import { priceWithVAT } from "utilities/costants/genericCostants";

type GemPackChoiceProps = {
	gemsPack: Inventory.GemsPack;
	selected: Inventory.GemsPacksName;
	setSelected: Dispatch<Inventory.GemsPacksName>;
};

export const GemPackChoice = ({ gemsPack, selected, setSelected }: GemPackChoiceProps) => {
	const [swiper, setSwiper] = useState({} as SwiperType);

	const Buttons = useMemo(
		() =>
			Object.entries(gemsPack)
				.sort((a, b) => a[1].price - b[1].price)
				.map(([key, item], i) => (
					<Grid key={`button-${key}`} item xs={(11 / Object.keys(gemsPack).length) * 2} lg={11 / Object.keys(gemsPack).length}>
						<PriceButton
							type="button"
							className="btn-gems-pack"
							active={selected === key}
							onClick={() => {
								setSelected(key as Inventory.GemsPacksName);
								swiper.slideTo(i);
							}}>
							€ {priceWithVAT(item.price)}
						</PriceButton>
					</Grid>
				)),
		[gemsPack, selected, swiper, setSelected]
	);

	return (
		<Grid item xs={12} md={4} xl={4}>
			<Grid container maxWidth="100%" flexDirection="column" justifyContent="space-around" p={{ xs: 1, lg: 0 }}>
				<Grid item width="100%" maxWidth="100%" mx="auto">
					<SwiperBox setSelected={setSelected} swiper={swiper} setSwiper={setSwiper} />
				</Grid>
				<Grid container justifyContent="space-between" flexDirection="row" alignItems="center" rowGap={2} mb={2}>
					{Buttons}
				</Grid>
			</Grid>
		</Grid>
	);
};
