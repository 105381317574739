import React, { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import { MarketContext } from "../../Providers/MarketProvider";
import { faEdit, faPlus, faReceipt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Requests } from "../../../types/Requests";
import { Inventory } from "../../../types/Inventory";
import { EditSubscriptionPanel, MARKET_ITEMS_TABS, MARKET_TABS } from "./Market";
import { AddRewardPanel, EditRewardsPanel, ReceiptsRewardsPanel } from "./Market/MarketRewards";
import { EditGemsPackPanel } from "./Market/MarketGemsPack";

export const DashboardAdminRewards = (): JSX.Element => {
	const { getItemsFromDB } = useContext(MarketContext);

	const [show, setShow] = useState(false);
	const [rewards, setRewards] = useState<Inventory.Reward[]>([] as Requests.Inventory.Reward[]);
	const [gemPacks, setGemPacks] = useState<Inventory.GemsPack>({} as Inventory.GemsPack);
	const [subscription, setSubscription] = useState<Inventory.SubscriptionItem>({} as Inventory.SubscriptionItem);

	const [activeMarketTab, setActiveMarketTab] = useState<number>(MARKET_TABS.ADD);
	const [activeItemTab, setActiveItemTab] = useState<number>(MARKET_ITEMS_TABS.REWARDS);

	const handleChangeMarketTab = (event: React.SyntheticEvent, newValue: number) => {
		setActiveMarketTab(newValue);
	};

	const handleChangeItemTab = (event: React.SyntheticEvent, newValue: number) => {
		setActiveItemTab(newValue);
	};

	const getMarketItems = useCallback(async () => {
		const items = await getItemsFromDB();
		setRewards(items.rewards);
		setGemPacks(items.gemsPack);
		setSubscription(items.subscription);
	}, [getItemsFromDB]);

	useEffect(() => {
		if (show) getMarketItems();
	}, [show, getMarketItems]);

	return (
		<>
			<button type="button" className="btn-no-fill" style={{ width: "20%" }} onClick={() => setShow(!show)}>
				<h3>{show ? "Hide" : "Show"} Market Items</h3>
			</button>
			<div style={{ display: show ? "flex" : "none", flexDirection: "column", alignItems: "center" }}>
				<Tabs
					value={activeMarketTab}
					onChange={handleChangeMarketTab}
					aria-label="Handle Market Tabs"
					TabIndicatorProps={{ style: { height: 8 } }}
					sx={{ mb: 5 }}>
					<Tab
						icon={<FontAwesomeIcon icon={faPlus as IconProp} className="fa-xl" />}
						iconPosition="start"
						label="ADD REWARD"
						value={MARKET_TABS.ADD}
						sx={{ color: "white" }}
					/>
					<Tab
						icon={<FontAwesomeIcon icon={faEdit as IconProp} className="fa-xl" />}
						iconPosition="start"
						label="EDIT ALL ITEMS"
						value={MARKET_TABS.EDIT}
						sx={{ color: "white" }}
					/>
					<Tab
						icon={<FontAwesomeIcon icon={faReceipt as IconProp} className="fa-xl" />}
						iconPosition="start"
						label="REWARD RECEIPTS"
						value={MARKET_TABS.RECEIPTS}
						sx={{ color: "white" }}
					/>
				</Tabs>

				<AddRewardPanel value={activeMarketTab} refetchNewItems={getMarketItems} />

				<EditPanel value={activeMarketTab}>
					<Tabs
						value={activeItemTab}
						onChange={handleChangeItemTab}
						aria-label="Handle Market Item Tabs"
						TabIndicatorProps={{ style: { height: 8 } }}
						sx={{ mb: 5 }}>
						<Tab label="GEMS PACK" value={MARKET_ITEMS_TABS.GEMS_PACKS} sx={{ color: "white" }} />
						<Tab label="REWARDS" value={MARKET_ITEMS_TABS.REWARDS} sx={{ color: "white" }} />
						<Tab label="SUBSCRIPTION" value={MARKET_ITEMS_TABS.SUBSCRIPTION} sx={{ color: "white" }} />
					</Tabs>
					<EditRewardsPanel
						value={activeItemTab}
						rewards={rewards}
						onDeleteRewardItem={(index) =>
							setRewards((prev) => {
								const newRewards = [...prev];
								newRewards.splice(index, 1);
								return newRewards;
							})
						}
						refetchNewItems={getMarketItems}
					/>
					<EditGemsPackPanel value={activeItemTab} gemsPacks={gemPacks} refetchNewItems={getMarketItems} />

					<EditSubscriptionPanel value={activeItemTab} subscription={subscription} refetchNewItems={getMarketItems} />
				</EditPanel>

				<ReceiptsRewardsPanel value={activeMarketTab} />
			</div>
		</>
	);
};

const EditPanel = ({ value, children }: { value: number; children: ReactNode }) => (
	<Grid container justifyContent="center" role="tabpanel" hidden={value !== MARKET_TABS.EDIT} gap={3}>
		{children}
	</Grid>
);
