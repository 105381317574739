import { DashboardStats } from "./DashboardStats";
import { DashboardAdminGiftCard } from "./DashboardAdminGiftCard";

import "../../../styles/DashboardAdmin.scss";
import { useEffect } from "react";
import { DashboardAdminPremiumUsers } from "./DashboardAdminPremiumUsers";
import { AnalyticsInfluencer } from "./AnalyticsInfluencer";
import { DashboardAdminRewards } from "./DashboardAdminMarket";

export const DashboardAdmin = ({ name }: { name?: string }): JSX.Element => {
	useEffect(() => {});
	return (
		<>
			<div className="dashboard-admin-container">
				<DashboardStats />
				<hr />
				<DashboardAdminPremiumUsers />
				<hr />
				<DashboardAdminGiftCard />
				<hr />
				<DashboardAdminRewards />
				<hr />
				<AnalyticsInfluencer />
			</div>
		</>
	);
};
